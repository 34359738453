import { CometChat } from "@cometchat-pro/chat";
import jwtDecode from "jwt-decode";
import { createContext, useCallback, useContext, useMemo } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useCandidateAPI } from "../shared/useAPI";
import { useLocalStorage } from "../shared/useLocalStorage";

export const AuthContext = createContext();

/** @typedef {"FREE" | "PREMIUM"} OrganizationPlan */
/**
 * @typedef AuthContextInterface
 * @property {string} accessToken
 * @property {(accessToken) => void} setAccessToken
 * @property {{ ROLE: UserRole }} decodedToken
 * @property {import("../candidates/CandidateAPI").Candidate & { chatAccessToken?: string, organizationPlan: OrganizationPlan }} user
 * @property {() => void} logout
 */
/** @type {() => AuthContextInterface} */
export const useAuth = () => {
  return useContext(AuthContext);
};

/** @type {() => OrganizationPlan | undefined} */
export const useOrganizationPlan = () => {
  const { user } = useAuth();
  if (!user) {
    return undefined;
  }
  return user.organizationPlan;
};

const useCurrentUserQuery = (accessToken) => {
  const api = useCandidateAPI();
  return useQuery({
    queryKey: ["api/users/me", accessToken],
    queryFn: async () => {
      return api
        .get("api/users/me", {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .json();
    },
    enabled: !!accessToken,
  });
};

/** @typedef {"ADMIN" | "ORGANIZATION_OPERATOR" | "ORGANIZATION_ADMIN"} UserRole */
/** @type {() => UserRole} */
export const useRole = () => {
  const auth = useAuth();
  return auth.decodedToken ? auth.decodedToken.ROLE : null;
};

export const AuthProvider = ({ children }) => {
  const [accessToken, setAccessToken] = useLocalStorage("access_token");
  const { data: user } = useCurrentUserQuery(accessToken);
  const decodedToken = useMemo(() => (accessToken ? jwtDecode(accessToken) : null), [accessToken]);

  const queryClient = useQueryClient();
  const logout = useCallback(async () => {
    setAccessToken(null);
    queryClient.removeQueries();
    localStorage.clear();
    try {
      await CometChat.logout();
    } catch (e) { }
  }, [queryClient, setAccessToken]);

  return (
    <AuthContext.Provider value={{ accessToken, setAccessToken, decodedToken, user, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
