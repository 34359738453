/** @jsxImportSource @emotion/react */
import { Dialog } from "@headlessui/react";
import { TrashIcon } from "@heroicons/react/outline";
import { FormProvider, useForm } from "react-hook-form";
import { EmptyState, EmptyStateDescription, EmptyStateTitle } from "../shared/EmptyState";
import { List } from "../shared/List";
import { FormGroup, Textarea } from "../shared/Form";
import { XIcon } from "@heroicons/react/solid";
import { Fragment, useState } from "react";
import { Button } from "../shared/Button";
import { useTranslation } from "react-i18next";
import "twin.macro";
import tw from "twin.macro";
import { Transition } from "../shared/Transition";
import { useAuth } from "../auth/AuthContext";
import { useFormatDate } from "../shared/useFormatDate";
import { useCandidatesOrganizationNoteQuery, usePostCandidateNoteMutation, useDeleteCandidateNoteMutation } from "./CandidateAPI";

const CandidateNoteItem = ({ candidateNote, userUuid, refetch }) => {

  const { t } = useTranslation();
  const formatDate = useFormatDate({ dateStyle: "medium" });
  let ownerName = userUuid === candidateNote.ownerUuid ? t("shared.you") : `${candidateNote.ownerFirstname} ${candidateNote.ownerLastname}`;

  const { mutate: deleteNote } = useDeleteCandidateNoteMutation();
  const onDeleteNote = async (e) => {
    e.preventDefault();
    await deleteNote(candidateNote.uuid, {
      onSuccess: () => {
        refetch();
      },
    });
  };
  return (
    <div>
      <div tw="m-2 flex justify-between">
        <div tw="text-left">
            {userUuid === candidateNote.ownerUuid && (
          <div tw="border rounded-md w-full text-left text-gray-900 bg-green-100 px-2 py-1">
              <span tw="w-full text-xs">{candidateNote.content}</span>
          </div>
            )}
            {userUuid !== candidateNote.ownerUuid && (
          <div tw="border rounded-md w-full text-left text-gray-900 bg-gray-100 px-2 py-1">
              <span tw="w-full text-xs">{candidateNote.content}</span>
          </div>
            )}
          <span tw="w-full text-xs text-gray-500 italic">{t("candidate-notes.author", { name: ownerName, date: formatDate(new Date(candidateNote.creationDate)) })}</span>
        </div>
        {userUuid === candidateNote.ownerUuid && (
          <div>
            <button
              type="button"
              css={[
                tw`max-h-10 mx-2 flex justify-end hover:(bg-red-50) text-red-600 text-xs`,
              ]}
              onClick={onDeleteNote}
            >
              <TrashIcon tw="-ml-1 mr-2 h-5 w-5 text-red-500 text-sm" />
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

const CandidateNoteList = ({ candidate, status, notes, user, refetch }) => {

  const { t } = useTranslation();
  return (
    <>
      {/* Error state */}
      {status === "error" && (
        <EmptyState>
          <EmptyStateTitle>{t("candidate-notes.failed-to-fetch-notes")}</EmptyStateTitle>
          <EmptyStateDescription>
            {t(
              "candidate-notes.candidate-notes.failed-to-fetch-notes-desc"
            )}
          </EmptyStateDescription>
          <Button onClick={refetch} tw="mt-8">
            {t("candidates.try-again")}
          </Button>
        </EmptyState>
      )}
      {/* Loading state */}
      {/* {status === "loading" && (
      <div></div>)} */}
      {/* Success state */}
      {/* Empty state */}
      {status === "success" && notes.length === 0 && (
        <>
          <span tw="my-4 text-gray-900">{t("candidate-notes.no-note")}</span>
        </>
      )}
      {/* List with candidates */}
      {status === "success" && notes.length !== 0 && (
        <List 
        tw="max-h-65-screen overflow-auto">
          {notes.map(note => (
            <CandidateNoteItem key={note.uuid} candidateNote={note} userUuid={user?.uuid} refetch={refetch} />
          ))}
        </List>
      )}
    </>
  );
}

export const CandidateCommentModal = ({ candidate, isOpen, onClose }) => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const [inputTextAreaValue, setInputTextAreaValue] = useState("");
  const { status, data, refetch } = useCandidatesOrganizationNoteQuery(candidate.uuid)

  const { mutate: addNoteToCandidateMutate, status: mutationStatus, error } = usePostCandidateNoteMutation();
  const formProps = useForm({
    defaultValues: { content: "" },
  });
  const { handleSubmit } = formProps;
  const addNoteToCandidate = async ({ content }) => {
    const note = {
      content: content,
      candidateUuid: candidate.uuid
    };
    await addNoteToCandidateMutate(note, {
      onSuccess: () => {
        resetInputField();
        refetch();
      },
    });
  };

  const handleUserInput = (e) => {
    setInputTextAreaValue(e.target.value);
  };

  const resetInputField = () => {
    setInputTextAreaValue("");
  };


  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        static
        tw="fixed z-[110] inset-0 overflow-y-auto"
        open={isOpen}
        onClose={onClose}
      >
        <div tw="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter={tw`ease-out duration-300`}
            enterFrom={tw`opacity-0`}
            enterTo={tw`opacity-100`}
            leave={tw`ease-in duration-200`}
            leaveFrom={tw`opacity-100`}
            leaveTo={tw`opacity-0`}
          >
            <Dialog.Overlay tw="fixed inset-0 bg-gray-500 bg-opacity-5 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span tw="w-96 float-right hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            tw="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-lg sm:my-8 sm:align-middle max-w-lg w-full sm:p-6 transform transition-all max-h-90-screen"
            enter={tw`ease-out duration-300`}
            enterFrom={tw`opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95`}
            enterTo={tw`opacity-100 translate-y-0 sm:scale-100`}
            leave={tw`ease-in duration-200`}
            leaveFrom={tw`opacity-100 translate-y-0 sm:scale-100`}
            leaveTo={tw`opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95`}
          >
            <div tw="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
              <button
                type="button"
                tw="bg-white p-0.5 rounded-md text-gray-400 hover:(bg-gray-50 text-gray-500) focus:(outline-none bg-gray-50 text-gray-500)"
                onClick={onClose}
              >
                <span tw="sr-only">{t("shared.close")}</span>
                <XIcon tw="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div>

              <div tw="mt-3 text-center sm:mt-5">
                <div tw="mt-2">
                  <CandidateNoteList
                    tw="max-h-65-screen"
                    candidate={candidate}
                    status={status}
                    notes={data}
                    user={user}
                    refetch={refetch}
                  >   
                  </CandidateNoteList>
                  <div>
                    <FormProvider {...formProps}>
                      <form onSubmit={handleSubmit(addNoteToCandidate)}>

                        <FormGroup>
                          <div tw="mb-4 w-full">
                            <div tw="bg-white rounded-t-lg">
                              <Textarea value={inputTextAreaValue} id="content" name="content" rows="4" tw="rounded-md w-full text-sm text-gray-900 bg-white" placeholder={t("candidate-notes.write-comment")} onChange={handleUserInput} required></Textarea>
                            </div>
                            <div tw="flex justify-end items-center py-2">
                              <button type="submit" tw="inline-flex items-center py-2.5 px-4 text-sm font-medium text-center text-white bg-blue-700 rounded-lg focus:ring-4 focus:ring-blue-200 hover:bg-blue-800">
                                {t("candidate-notes.comment")}
                              </button>
                            </div>
                          </div>
                        </FormGroup>
                      </form>
                    </FormProvider>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
