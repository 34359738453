import { useCallback, useState } from "react";

export const useDisclosure = (/** @type {boolean | () => boolean} */ intialState) => {
  const [isOpen, setIsOpen] = useState(intialState);
  const onOpen = useCallback(() => setIsOpen(true), []);
  const onClose = useCallback(() => setIsOpen(false), []);
  const onToggle = useCallback(() => setIsOpen((isOpen) => !isOpen), []);
  return {
    isOpen,
    setIsOpen,
    onOpen,
    onClose,
    onToggle,
  };
};
