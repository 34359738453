/** @jsxImportSource @emotion/react */
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "twin.macro";
import { Logo } from "../shared/Logo";
import AuthLayoutBackgroundImage from "./AuthLayoutBackgroundImage.gif";

export const AuthLayoutTitle = ({ children, ...props }) => {
  return (
    <h1 tw="mt-6 text-3xl leading-9 font-extrabold text-gray-900" {...props}>
      {children}
    </h1>
  );
};

export const AuthLayoutTitleSpecialOffer = ({ children1, children2, children3, ...props }) => {
  return (
    <h1 tw="mt-6 text-3xl leading-9 font-extrabold text-gray-900" {...props}>
      <span>{children1}</span><sup>{children2}</sup><span>{children3}</span>
    </h1>
  );
};

export const AuthLayoutDescription = (props) => {
  return <p tw="mt-2 text-sm text-gray-600" {...props} />;
};

export const AuthLayoutHeader = ({ children, ...props }) => {
  return (
    <header {...props}>
      <Link to="/">
        {props.schoolName ? (
          <div tw="flex items-end">
            <img
              tw="w-auto h-16 lg:h-16 rounded-md"
              src={`${process.env.REACT_APP_DEV_PUBLIC_MEDIA_URL}/organization/logo/original/${props.schoolName}.jpeg`}
              alt={props.schoolName}
              onError={(event) => (event.target.style.display = "none")}
            />
            <span tw="ml-2 mr-2 text-gray-400">by</span>
            <Logo tw="h-8 w-auto" />
          </div>
        ) : (
          <div tw="flex items-end">
            <img
              tw="w-auto h-8 lg:h-8 rounded-md"
              src={`${process.env.REACT_APP_DEV_PUBLIC_MEDIA_URL}/organization/logo/original/${process.env.REACT_APP_APPLICATION_DOMAIN}.jpeg`}
              alt={props.schoolName}
              onError={(event) => (event.target.style.display = "none")}
            />
          </div>
        )}
      </Link>
      {children}
    </header>
  );
};

const AuthLayout = ({ children }) => {
  const { t } = useTranslation();
  return (
    <>
      <div tw="min-h-screen bg-white flex">
        <div tw="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <main tw="mx-auto w-full max-w-sm lg:w-96">{children}</main>
        </div>
        <div tw="hidden lg:block relative w-0 flex-1 bg-gray-100">
          <h1 tw="absolute left-1/2 transform -translate-x-1/2 mt-16 2xl:mt-10 text-2xl 2xl:text-3xl leading-9 font-extrabold text-gray-900">
            {t("auth.simplify-your-recruitment")}
          </h1>
          <img
            tw="absolute left-1/2 transform -translate-x-1/2 top-1/2 -translate-y-1/2 w-5/6 rounded-3xl"
            src={`/${process.env.REACT_APP_FAVICONS}/AuthLayoutBackgroundImage.gif`}
            alt=""
          />
        </div>
      </div>
    </>
  );
};

export default AuthLayout;
