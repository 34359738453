// @ts-check
import { Loader } from "@googlemaps/js-api-loader";
import { useQuery } from "react-query";

/** @type {(loaderOptions: Omit<import("@googlemaps/js-api-loader").LoaderOptions, "apiKey">) => import("react-query").UseQueryResult<google>} */
export const useGoogleAPIQuery = (loaderOptions) => {
  return useQuery(["@googlemaps/js-api-loader", loaderOptions], async () => {
    const loader = new Loader({
      apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
      ...loaderOptions,
    });
    await loader.load();
    return window.google;
  });
};

/** @type {(request: google.maps.GeocoderRequest) => Promise<google.maps.GeocoderResult[]>} */
export const geocode = (request) => {
  return new Promise((resolve, reject) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode(request, (results, status) => {
      if (status !== window.google.maps.GeocoderStatus.OK) {
        return reject(status);
      }
      return resolve(results);
    });
  });
};

/** @type {(request: google.maps.GeocoderRequest) => import("react-query").UseQueryResult<google.maps.GeocoderResult[]>} */
export const useGeocodeQuery = (request) => {
  const { data: google } = useGoogleAPIQuery({ libraries: ["places"] });
  return useQuery(
    ["geocode", request],
    async () => {
      return geocode(request);
    },
    {
      enabled: !!request && !!google,
    }
  );
};

/** @type {(geocoderResult: google.maps.GeocoderResult) => { line?: string, city?: string, country?: string}} */
export const resolveAddressFromGeocoderResult = (geocoderResult) => {
  const streetNumber = geocoderResult.address_components.find((component) =>
    component.types.includes("street_number")
  );
  const route = geocoderResult.address_components.find((component) =>
    component.types.includes("route")
  );
  const postalCode = geocoderResult.address_components.find((component) =>
    component.types.includes("postal_code")
  )?.short_name;
  const city = geocoderResult.address_components.find((component) =>
    component.types.includes("locality")
  )?.short_name;
  const country = geocoderResult.address_components.find((component) =>
    component.types.includes("country")
  )?.long_name;

  const addressLine = [streetNumber?.short_name, route?.short_name].filter(Boolean).join(" ");

  return {
    line: addressLine,
    city: [postalCode, city].filter(Boolean).join(" "),
    country: country,
  };
};
