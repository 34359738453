// @ts-check
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { useCandidateAPI } from "../shared/useAPI";

/** @typedef {'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY' | 'SUNDAY'} WeekDay */
/** @typedef {'morning' | 'afternoon' | 'evening'} DayPart */
/** @typedef {{ [key in DayPart]: boolean }} Availability */
/** @typedef {{ [key in WeekDay]: Availability }} Availabilities */

/**
 * @typedef JobAdSkill
 * @property {number} id
 * @property {string} name
 * @property {string} libelleDe
 * @property {string} libelleEn
 * @property {string} libelleEs
 * @property {string} libelleFr
 * @property {string} libelleIt
 * @property {string} libelleNl
 * @property {string} libellePt
 * @property {number} priority
 * @property {boolean} accreditation
 */

/** @typedef {"A" | "B" | "C" | "CE" | "C1" | "C1E" | "D" | "DE" | "D1" | "D1E"} DrivingLicence */

/**
 * @typedef Intelli7ScoringCriteria
 * @property {boolean} taskOriented
 * @property {boolean} actionCapacity
 * @property {boolean} organisation
 * @property {boolean} prioritiesOriented
 * @property {boolean} specialisation
 * @property {boolean} team
 * @property {boolean} explicite
 * @property {boolean} listenning
 * @property {boolean} drivingCapacity
 * @property {boolean} guidance
 * @property {boolean} discipline
 * @property {boolean} serviceOriented
 * @property {boolean} changeHability
 * @property {boolean} combativeness
 * @property {boolean} stressSensibility
 */

/**
 * @typedef Intelli7V2ScoringCriteria
 * @property {boolean} audacity
 * @property {boolean} perseverance
 * @property {boolean} intuition
 * @property {boolean} selfOrganization
 * @property {boolean} dynamism
 * @property {boolean} creativity
 * @property {boolean} adaptation
 * @property {boolean} teamSpirit
 * @property {boolean} openListening
 * @property {boolean} understandingOthers
 * @property {boolean} contactSkills
 * @property {boolean} serviceSense
 * @property {boolean} framing
 * @property {boolean} drivingCapacity
 * @property {boolean} cognitiveFlexibility
 * @property {boolean} organization
 * @property {boolean} problemSolving
 * @property {boolean} decisionMaking
 * @property {boolean} actionCapacity
 * @property {boolean} senseOfPriorities
 * @property {boolean} initiativeCapacity
 */

/**
 * @typedef {"DRAFT" | "PUBLISHED" | "DISABLED" | "CANCELED" | "PUBLICATION_REQUEST"} JobAdStatus
 */

/**
 * @typedef {"MATCHED" | "APPLY" | "PRESELECTED"| "SELECTED" | "REFUSED"} JobAdCandidateStatus
 */

/** @typedef {{ currency?: "EUR", min?: number, max?: number, benefits?: string, period?: "HOUR" | "MONTH" | "YEAR" }} JobAdSalary */

/** @typedef {"UNPAID" | "PAID_BY_PLAN" | "PAID"} JobAdPaymentStatus */

/**
 * @typedef JobAd
 * @property {string} uuid
 * @property {JobAdStatus} status
 * @property {JobAdPaymentStatus} paymentStatus
 * @property {string} title
 * @property {string} jobCode
 * @property {string} jobTitle
 * @property {"CDI" | "CDD" | "INTERIM" | "FREELANCER" | "INTERN" | "APPRENTICE"} contract
 * @property {number} durationInMonth
 * @property {"FULL_TIME" | "PART_TIME"} employmentType
 * @property {number} yearsOfExperience
 * @property {number} vacancy
 * @property {Availabilities} availabilities
 * @property {JobAdSkill[]} skills
 * @property {JobAdSkill[]} activities
 * @property {JobAdSkill[]} accreditations
 * @property {Intelli7V2ScoringCriteria} intelli7V2ScoringCriteria
 * @property {string[]} languages
 * @property {DrivingLicence[]} licences
 * @property {boolean} needsVehicle
 * @property {{ address: string, latitude: string, longitude: string, placeId?: string }} location
 * @property {number} businessSectorId
 * @property {JobAdSalary} [remuneration]
 * @property {string} content
 * @property {string} organizationDescription
 * @property {boolean} executiveJobOffer
 */

/**
 * @typedef JobAdExtension
 * @property {string} uuid
 * @property {number} totalCandidates
 * @property {number} appliedCandidates
 * @property {number} recruitedCandidates
 * @property {number} selectedCandidates
 */

/** @type {(searchParams?: import("../shared/useAPI").SearchParams) => import("react-query").UseQueryResult<import("../shared/useAPI").PageableResult<JobAd>>} */
export const useJobAdsQuery = searchParams => {
  const api = useCandidateAPI();
  return useQuery({
    queryKey: [`api/jobad/domain/${process.env.REACT_APP_APPLICATION_DOMAIN}`, searchParams],
    queryFn: async () => api.get(`api/jobad/domain/${process.env.REACT_APP_APPLICATION_DOMAIN}`, { searchParams }).json(),
    keepPreviousData: true
  });
};

/** @type {(jobAdId: string) => import("react-query").UseQueryResult<JobAd, Error>} */
export const useJobAdByIdQuery = jobAdId => {
  const api = useCandidateAPI();
  return useQuery({
    queryKey: `api/jobad/${jobAdId}`,
    queryFn: async () => api.get(`api/jobad/${jobAdId}`).json(),
    enabled: !!jobAdId
  });
};

/** @type {(jobAdId: string) => import("react-query").UseQueryResult<JobAdExtension, Error>} */
export const useJobAdCandidatesCountersByIdQuery = (jobAdId) => {
  const api = useCandidateAPI();
  return useQuery({
    queryKey: `api/jobad/domain/${process.env.REACT_APP_APPLICATION_DOMAIN}/${jobAdId}/candidatescounters`,
    queryFn: async () => api.get(`api/jobad/domain/${process.env.REACT_APP_APPLICATION_DOMAIN}/${jobAdId}/candidatescounters`).json(),
    enabled: !!jobAdId,
  });
};


/** @type {(jobAdType: string, schoolID: string, subtype: string) => import("react-query").UseMutationResult<JobAd, unknown, Partial<JobAd>>} */
export const useCreateJobAdMutation = (jobAdType, schoolID, subtype) => {
  const api = useCandidateAPI();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (
      json = {
        availabilities: {
          MONDAY: {
            morning: true,
            afternoon: true,
            evening: false
          },
          TUESDAY: {
            morning: true,
            afternoon: true,
            evening: false
          },
          WEDNESDAY: {
            morning: true,
            afternoon: true,
            evening: false
          },
          THURSDAY: {
            morning: true,
            afternoon: true,
            evening: false
          },
          FRIDAY: {
            morning: true,
            afternoon: true,
            evening: false
          },
          SATURDAY: {
            morning: false,
            afternoon: false,
            evening: false
          },
          SUNDAY: {
            morning: false,
            afternoon: false,
            evening: false
          }
        },
        remuneration: {
          period: "MONTH"
        }
      }
    ) => {
      // JobAd creation API does not accept any arguments, we create the job ad and update it immediatly.
      const jobAd = await api
        .post(`api/jobad/create`, {
          json: {
            type: jobAdType,
            optionalSchoolUUIDs: schoolID,
            subtype: subtype,
            domainUniqueName: process.env.REACT_APP_APPLICATION_DOMAIN,
            newAd: {}
          }
        })
        .json();
      return api.patch(`api/jobad/domain/${process.env.REACT_APP_APPLICATION_DOMAIN}/${jobAd.uuid}`, { json }).json();
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries([`api/jobad/domain/${process.env.REACT_APP_APPLICATION_DOMAIN}`, jobAdType]);
    }
  });
};

/** @type {() => import("react-query").UseMutationResult<JobAd, unknown, Partial<JobAd>>} */
export const useUpdateJobAdMutation = () => {
  const api = useCandidateAPI();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (json = {}) => {
      return api
        .patch(`api/jobad/domain/${process.env.REACT_APP_APPLICATION_DOMAIN}/${json.uuid}`, {
          json
        })
        .json();
    },
    onSuccess: async (_, { uuid }) => {
      await queryClient.invalidateQueries(`api/jobad/domain/${process.env.REACT_APP_APPLICATION_DOMAIN}`);
      queryClient.removeQueries(`api/jobad/${uuid}`);
      await queryClient.invalidateQueries(`api/jobad/${uuid}`);
    }
  });
};

/** @type {() => import("react-query").UseMutationResult<JobAd, Error, { uuid: string }>} */
export const usePublishJobAdMutation = () => {
  const api = useCandidateAPI();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ uuid }) => api.put(`api/jobad/domain/${process.env.REACT_APP_APPLICATION_DOMAIN}/${uuid}/publish`).json(),
    onSuccess: async (_, { uuid }) => {
      await queryClient.invalidateQueries(`api/jobad/domain/${process.env.REACT_APP_APPLICATION_DOMAIN}`);
      queryClient.removeQueries(`api/jobad/domain/${process.env.REACT_APP_APPLICATION_DOMAIN}/${uuid}`);
      await queryClient.invalidateQueries(`api/jobad/domain/${process.env.REACT_APP_APPLICATION_DOMAIN}/${uuid}`);
      // Token count might change when publishing a job ad, invalidate organization query.
      await queryClient.invalidateQueries("api/users/me/organization");
    }
  });
};

/** @typedef {"information" | "skills" | "qualities" | "requirements" | "descriptions" | "preview" | "candidates" | "publication-requested"} JobAdCreationStep */

/** @typedef {keyof JobAd} JobAdField */
/** @type {JobAdField[]} */
const informationStepRequiredFields = [
  "title",
  "jobCode",
  "jobTitle",
  "contract",
  "yearsOfExperience",
  "vacancy",
  "availabilities"
];
/** @type {(jobAd: JobAd) => boolean} */
export const isInformationStepCompleted = jobAd => {
  const isFieldCompleted = field => jobAd[field] !== undefined && jobAd[field] !== null;
  return informationStepRequiredFields.every(isFieldCompleted);
};

/** @type {(jobAd: JobAd) => boolean} */
export const isSkillsStepCompleted = jobAd => {
  return (
    (jobAd.skills && jobAd.skills.length >= 3 && jobAd.activities && jobAd.activities.length >= 3)
  );
};

/** @type {(jobAd: JobAd) => boolean} */
export const isQualitiesStepCompleted = jobAd => {
  return (
    (jobAd.intelli7V2ScoringCriteria &&
      Object.values(jobAd.intelli7V2ScoringCriteria).filter(Boolean).length >= 3)
  );
};

/** @type {JobAdField[]} */
const requirementStepRequiredFields = [
  "languages",
  "licences",
  "needsVehicle",
  "location",
  "businessSectorId",
  "remuneration"
];

/** @type {(jobAd: JobAd) => boolean} */
export const isRequirementStepCompleted = jobAd => {
  const isFieldCompleted = field => jobAd[field] !== undefined && jobAd[field] !== null;
  return requirementStepRequiredFields.every(isFieldCompleted);
};

/** @type {(jobAd: JobAd) => boolean} */
export const isDescriptionStepCompleted = jobAd => {
  return jobAd.content !== undefined && jobAd.content !== null && jobAd.content !== "";
};

/** @type {(jobAd: JobAd) => boolean} */
export const isPreviewStepCompleted = jobAd => {
  return jobAd.status !== "DRAFT";
};

/** @type {(jobAd: JobAd) => JobAdCreationStep} */
export const getCurrentStep = jobAd => {
  // if (jobAd.status === "PUBLICATION_REQUEST") {
  //   return "publication-requested";
  // }
  if (jobAd.status !== "DRAFT") {
    return "candidates";
  }
  if (!isInformationStepCompleted(jobAd)) {
    return "information";
  }
  if (!isSkillsStepCompleted(jobAd)) {
    return "skills";
  }
  if (!isQualitiesStepCompleted(jobAd)) {
    return "qualities";
  }
  if (!isRequirementStepCompleted(jobAd)) {
    return "requirements";
  }
  if (!isDescriptionStepCompleted(jobAd)) {
    return "descriptions";
  }
  if (!isPreviewStepCompleted(jobAd)) {
    return "preview";
  }
  return "candidates";
};

/** @type {(jobAdId: string, searchParams?: import("../shared/useAPI").SearchParams) => import("react-query").UseQueryResult<import("../shared/useAPI").PageableResult<import("../candidates/CandidateAPI").Candidate>>} */
export const useJobAdCandidatesQuery = (jobAdId, searchParams) => {
  const api = useCandidateAPI();
  return useQuery({
    queryKey: [`api/jobad/domain/${process.env.REACT_APP_APPLICATION_DOMAIN}/${jobAdId}/candidates`, searchParams],
    queryFn: async () => api.get(`api/jobad/domain/${process.env.REACT_APP_APPLICATION_DOMAIN}/${jobAdId}/candidates`, { searchParams }).json(),
    enabled: !!jobAdId
  });
};

/** @type {(jobAdId: string, candidateId: string, source: string) => import("react-query").UseQueryResult<import("../candidates/CandidateAPI").Candidate>} */
export const useJobAdCandidateByIdQuery = (jobAdId, candidateId, source) => {
  const api = useCandidateAPI();
  return useQuery({
    queryKey: `api/jobad/domain/${process.env.REACT_APP_APPLICATION_DOMAIN}/${jobAdId}/candidates/${candidateId}`,
    queryFn: async () => api.get(`api/jobad/domain/${process.env.REACT_APP_APPLICATION_DOMAIN}/${jobAdId}/candidates/${candidateId}?source=${source}`).json(),
    enabled: !!jobAdId && !!candidateId
  });
};

/** @type {(jobAdId: string, searchParams?: { page?: number, size?: number, status?: JobAdCandidateStatus }) => import("react-query").UseQueryResult<import("../shared/useAPI").PageableResult<import("../candidates/CandidateAPI").Candidate>>} */
export const useJobAdCandidatesByStatusQuery = (jobAdId, searchParams) => {
  const api = useCandidateAPI();
  return useQuery({
    queryKey: [`api/jobad/domain/${process.env.REACT_APP_APPLICATION_DOMAIN}/${jobAdId}/candidates/status`, searchParams],
    queryFn: async () => api.get(`api/jobad/domain/${process.env.REACT_APP_APPLICATION_DOMAIN}/${jobAdId}/candidates/status`, { searchParams }).json(),
    enabled: !!jobAdId
  });
};

/** @typedef {{ jobAdUuid: string, status: JobAdCandidateStatus, candidateUuid: string, source: string }} UseUpdateJobAdCandidateStatusVariables */
/** @type {() => import("react-query").UseMutationResult<void, unknown, UseUpdateJobAdCandidateStatusVariables>} */
export const useUpdateJobAdCandidateStatusMutation = () => {
  const api = useCandidateAPI();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ jobAdUuid, status, candidateUuid, source }) => {
      await api.put(`api/jobad/domain/${process.env.REACT_APP_APPLICATION_DOMAIN}/${jobAdUuid}/candidates/status`, {
        json: {
          status,
          candidateUuid,
          source
        }
      });
    },
    onSuccess: async (_, { jobAdUuid, candidateUuid }) => {
      await queryClient.invalidateQueries(`api/jobad/domain/${process.env.REACT_APP_APPLICATION_DOMAIN}/${jobAdUuid}/candidates`);
      await queryClient.invalidateQueries(`api/jobad/domain/${process.env.REACT_APP_APPLICATION_DOMAIN}/${jobAdUuid}/candidates/status`);
      await queryClient.invalidateQueries(`api/jobad/domain/${process.env.REACT_APP_APPLICATION_DOMAIN}/${jobAdUuid}/candidates/${candidateUuid}`);
    }
  });
};

export const isJobAdPaymentCompleted = (/** @type {import("./JobAdAPI").JobAd} */ jobAd) => {
  return jobAd.paymentStatus !== null && jobAd.paymentStatus !== "UNPAID";
};

export const isJobAdSchoolPaymentCompleted = jobAd => {
  return jobAd.paymentStatus !== null && jobAd.paymentStatus !== "PAID_BY_SCHOOL";
};

/** @type {(jobAd: JobAd) => string} */
export const getJobAdURL = jobAd => {
  return `${process.env.REACT_APP_JOBOOK_APP_URL}/?from-job-ad=${jobAd.uuid}`;
};

/** @type {(jobAdId: string, searchParams?: { page?: number, size?: number, status?: JobAdCandidateStatus }) => import("react-query").UseQueryResult<import("../shared/useAPI").PageableResult<import("../candidates/CandidateAPI").Candidate>>} */
export const useJobAdCountByStatus = searchParams => {
  const api = useCandidateAPI();
  return useQuery({
    queryKey: [`api/jobad/domain/${process.env.REACT_APP_APPLICATION_DOMAIN}/countByStatus`, searchParams],
    queryFn: async () => api.get(`api/jobad/domain/${process.env.REACT_APP_APPLICATION_DOMAIN}/countByStatus`, { searchParams }).json()
  });
};

/** @type {(jobAdId: string, searchParams?: { page?: number, size?: number, status?: JobAdCandidateStatus }) => import("react-query").UseQueryResult<import("../shared/useAPI").PageableResult<import("../candidates/CandidateAPI").Candidate>>} */
export const useGetMyJobAdsTags = searchParams => {
  const api = useCandidateAPI();
  return useQuery({
    queryKey: ["api/users/me/organization/tags", searchParams],
    queryFn: async () => api.get("api/users/me/organization/tags", { searchParams }).json()
  });
};

/** @typedef {{ jobAdUuid: string, location: location }} UseDuplicateJobAdVariables */
/** @type {() => import("react-query").UseMutationResult<void, unknown, UseDuplicateJobAdVariables>} */
export const useDuplicateJobAdMutation = () => {
  const history = useHistory();
  const candidateAPI = useCandidateAPI();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ jobAdUuid, location }) => {
      const result = await candidateAPI
        .post(`api/jobad/domain/${process.env.REACT_APP_APPLICATION_DOMAIN}/${jobAdUuid}/duplicate`, {
          json: location
        })
        .json();
      history.push(`/job-ads/${result.uuid}`);
      return result;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(`api/jobad/domain/${process.env.REACT_APP_APPLICATION_DOMAIN}`);
    }
  });
};

/** @typedef {{ jobAdUuid: string }} UseCloseJobAdVariables */
/** @type {() => import("react-query").UseMutationResult<void, unknown, UseCloseJobAdVariables>} */
export const useCloseJobAdMutation = () => {
  const history = useHistory();
  const candidateAPI = useCandidateAPI();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ jobAdUuid }) => {
      const result = await candidateAPI.put(`api/jobad/domain/${process.env.REACT_APP_APPLICATION_DOMAIN}/${jobAdUuid}/close`).json();
      history.push(`/job-ads/${result.uuid}`);
      return result;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(`api/jobad/domain/${process.env.REACT_APP_APPLICATION_DOMAIN}`);
    }
  });
};

/** @type {() => import("react-query").UseMutationResult<void, unknown, UseCloseJobAdVariables>} */
export const useDeleteJobAd = () => {
  const history = useHistory();
  const candidateAPI = useCandidateAPI();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (jobAdUuid) => {
      const result = await candidateAPI.delete(`api/jobad/domain/${process.env.REACT_APP_APPLICATION_DOMAIN}/${jobAdUuid}/delete`).json();
    },
    onSuccess: () => {
      queryClient.invalidateQueries(`api/jobad/domain/${process.env.REACT_APP_APPLICATION_DOMAIN}`);
      queryClient.invalidateQueries(`api/jobad/domain/${process.env.REACT_APP_APPLICATION_DOMAIN}/countByStatus`)
      history.push(`/job-ads`);
    },
  });
};

export const useJobAdSchoolRejectMutation = jobAdId => {
  const api = useCandidateAPI();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async () => {
      api.put(`api/school/domain/${process.env.REACT_APP_APPLICATION_DOMAIN}/jobad/${jobAdId}/reject`);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(`api/school/domain/${process.env.REACT_APP_APPLICATION_DOMAIN}/my-jobads`);
    }
  });
};

export const useJobAdSchoolAcceptMutation = jobAdId => {
  const api = useCandidateAPI();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async () => {
      api.put(`api/school/domain/${process.env.REACT_APP_APPLICATION_DOMAIN}/jobad/${jobAdId}/accept`);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(`api/school/domain/${process.env.REACT_APP_APPLICATION_DOMAIN}/my-jobads`);
    }
  });
};

/** @typedef {{ jobAdUuid: string, candidateUuid: string }} UsePushJobAdToCandidateVariables */
/** @type {() => import("react-query").UseMutationResult<void, APIError, UsePushJobAdToCandidateVariables>} */

export const usePushJobAdToCandidateMutation = () => {
  const api = useCandidateAPI();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ jobAdUuid, candidateUuid }) => {
      console.log(`about to call : api/jobad/domain/${process.env.REACT_APP_APPLICATION_DOMAIN}/${jobAdUuid}/push/${candidateUuid}`);

      try {
        await api.put(`api/jobad/domain/${process.env.REACT_APP_APPLICATION_DOMAIN}/${jobAdUuid}/push/${candidateUuid}`);
      } catch (e) {
        if (e.name === "APIError") {
          console.log(e.payload.message);
          if (e.payload.message === "business:candidate.jobad.already.pushed") {
            //Do something
          }
        }
      }

    },
    onSuccess: async (_, { jobAdUuid, candidateUuid }) => {
      await queryClient.invalidateQueries(`api/jobad/domain/${process.env.REACT_APP_APPLICATION_DOMAIN}/${jobAdUuid}/candidates`);
      await queryClient.invalidateQueries(`api/jobad/domain/${process.env.REACT_APP_APPLICATION_DOMAIN}/${jobAdUuid}/candidates/status`);
      await queryClient.invalidateQueries(`api/jobad/domain/${process.env.REACT_APP_APPLICATION_DOMAIN}/${jobAdUuid}/candidates/${candidateUuid}`);

    }

  });
};


/** @type {(jobAdUuid: string) => import("react-query").UseQueryResult<string, Error>} */
export const useDownloadJobAdQrCodeQuery = (jobAdUuid) => {
  const api = useCandidateAPI();
  return useQuery({
    queryKey: `api/jobad/domain/${process.env.REACT_APP_APPLICATION_DOMAIN}/${jobAdUuid}/qrcode`,
    queryFn: async () => {
      try {
        const blob = await api.get(`api/jobad/domain/${process.env.REACT_APP_APPLICATION_DOMAIN}/${jobAdUuid}/qrcode`).blob();
        return URL.createObjectURL(blob);
      } catch (error) {
        throw error;
      }
    },
  });
};
/** @type {(jobAdUuid: string) => import("react-query").UseQueryResult<string, Error>} */
export const useJobAdAiDescriptionQuery = (jobAdUuid) => {
  const api = useCandidateAPI();
  return useQuery({
    queryKey: `api/jobad/domain/${process.env.REACT_APP_APPLICATION_DOMAIN}/${jobAdUuid}/aidescription`,
    queryFn: async () => {
      try {
        const data = await api.get(`api/jobad/domain/${process.env.REACT_APP_APPLICATION_DOMAIN}/${jobAdUuid}/aidescription`).text();
        console.log(data);
        return data;
      } catch (error) {
        throw error;
      }
    },
    enabled: false
  });
};
