import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { StrictMode } from "react";
// import ReactGA from "react-ga";
import { render } from "react-dom";
import App from "./App";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  release: `jobook@${process.env.REACT_APP_VERSION}`,
  environment: `dashboard-${process.env.REACT_APP_SENTRY_ENV}`,
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

// if (process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_CODE) {
//   ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_CODE);
// }

render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById("root")
);
