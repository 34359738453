export const languageLabel = {
  de: "languages.de",
  en: "languages.en",
  ar: "languages.ar",
  es: "languages.es",
  fr: "languages.fr",
  it: "languages.it",
  lb: "languages.lb",
  nl: "languages.nl",
  pl: "languages.pl",
  pt: "languages.pt",
  ro: "languages.ro",
  ru: "languages.ru",
  cs: "languages.cs",
  tr: "languages.tr",
};

export const languageLevelLabel = {
  levelA: "languages.level-a",
  levelB: "languages.level-b",
  levelC: "languages.level-c",
};
