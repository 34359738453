/** @jsxImportSource @emotion/react */
import "twin.macro";
import { getDisplayName, useCandidatePictureURLQuery } from "../candidates/CandidateAPI";

/** @type {(name: string) => string} */
const getInitials = (name) => {
  if (!name) {
    return null;
  }
  if (name.startsWith("+")) {
    // For phone numbers, only keep the first two chars
    return name.slice(0, 2);
  }
  return (
    name
      // For email addresses, only keep the part before the address domain
      .split("@")[0]
      .split(/[ .-]/g)
      // Only keep the first two parts
      .filter((_, index) => index < 2)
      .map((part) => part[0])
      .join("")
  );
};

export const Avatar = ({ user, ...props }) => {
  const { data: picture } = useCandidatePictureURLQuery(user?.uuid);
  return (
    <>
      {picture ? (
        <span tw="relative flex-shrink-0 h-12 w-12 text-sm rounded-full overflow-hidden" {...props}>
          <img src={picture} tw="absolute inset-0 object-cover" alt="" />
        </span>
      ) : (
        <>
          <span
            tw="relative flex-shrink-0 h-12 w-12 text-sm rounded-full overflow-hidden"
            {...props}
          >
            <span
              tw="absolute flex items-center justify-center font-medium inset-0 leading-none uppercase bg-gray-500 text-white"
              {...props}
            >
              {getInitials(getDisplayName(user))}
            </span>
          </span>
        </>
      )}
    </>
  );
};
