/** @jsxImportSource @emotion/react */
import { BriefcaseIcon, LocationMarkerIcon, UsersIcon } from "@heroicons/react/solid";
import qs from "qs";
import { useState } from "react";
import { useRole } from "../auth/AuthContext";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import "twin.macro";
import tw from "twin.macro";
import { useJobAdSchoolQuery } from "../school/SchoolAPI";
import { Badge, BadgeDot } from "../shared/Badge";
import { Button, StatusButton } from "../shared/Button";
import { EmptyState, EmptyStateDescription, EmptyStateTitle } from "../shared/EmptyState";
import { List, ListItem, ListItemContent, ListItemLink } from "../shared/List";
import { Pagination } from "../shared/Pagination";
import { Skeleton } from "../shared/Skeleton";
import { useFormatDate } from "../shared/useFormatDate";
import { useSearchParams } from "../shared/useSearchParams";
import { useJobAdCountByStatus, useJobAdCandidatesCountersByIdQuery } from "./JobAdAPI";
import { StatusRing } from "./JobAdListRoute";

/** @type {(status: import("./JobAdAPI").JobAdStatus) => import("../shared/Badge").BadgeProps["color"]} */
const getJobAdStatusColor = (status) => {
  switch (status) {
    case "DRAFT":
      return "gray";
    case "PUBLISHED":
      return "indigo";
    case "EXPIRED":
      return "pink";
    case "CANCELED":
      return "red";
    case "DISABLED":
      return "gray";
    case "PUBLICATION_REQUEST":
      return "yellow";
    default:
      return "gray";
  }
};

export const capitalize = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

/** @type {import("react").FC<{ jobAd: import("./JobAdAPI").JobAd }>} */
const JobAdListItem = ({ jobAd, selected, clickCallback }) => {
  const { t } = useTranslation();
  const { page, adStatus } = useSearchParams();
  const { data: jobAdExtension, status: jobAdExtensionStatus } = useJobAdCandidatesCountersByIdQuery(jobAd.uuid);
  const formatDate = useFormatDate({ dateStyle: "short" });
  return (
    <ListItem onClick={() => clickCallback(jobAd.uuid)} active={selected === jobAd.uuid}>
      <ListItemLink
        to={{
          pathname:
            jobAd.status === "PUBLICATION_REQUEST"
              ? `/job-ads/${jobAd.uuid}/preview`
              : `/job-ads/${jobAd.uuid}`,
          search: qs.stringify({ page, adStatus }, { addQueryPrefix: true }),
        }}
        active={selected === jobAd.uuid}
      >
        <ListItemContent tw="block">
          <div tw="flex items-center justify-between space-x-2">
            <p tw="text-sm font-medium text-gray-900 truncate" title={jobAd.title}>
              {jobAd.title || <span tw="text-gray-300">{t("job-ads.title-placeholder")}</span>}
            </p>
            <JobAdStatusBadge status={jobAd.status} />
          </div>
          <div tw="flex items-center justify-between mt-2 space-x-2">
            <div tw="flex items-center"
            // onMouseEnter={() => setIsHovering(true)}
            // onMouseLeave={() => setIsHovering(false)}
            >
              <UsersIcon tw="flex-shrink-0 mr-1.5 h-3.5 w-3.5 text-gray-400" aria-hidden="true" />
              {jobAdExtensionStatus === "success" && jobAdExtension != null && jobAdExtension.totalCandidates != null && (
                <p tw="text-xs text-gray-500 truncate">
                  {
                    jobAdExtension.totalCandidates > 0 ? (
                      <span>{t("job-ads.candidateCount", { newCount: jobAdExtension.appliedCandidates, count: jobAdExtension.totalCandidates })}</span>
                    ) : (
                      <span>{t("job-ads.noCandidateCount")}</span>
                    )
                  }
                  {
                    jobAdExtension.recruitedCandidates && jobAdExtension.recruitedCandidates > 0 ? (
                      <span tw="text-green-500">  ({jobAdExtension.recruitedCandidates})</span>
                    ) : (<></>)
                  }
                </p>
              )}
              {/* {isHovering && (
                <div tw="absolute bg-white p-2 border rounded-lg shadow-lg -mt-10 text-xs text-gray-500 truncate">
                <span tw="block">{t("job-ads.noCandidateCount")}</span>
                <span tw="block">{t("job-ads.noCandidateCount")}</span>
                <span tw="block">{t("job-ads.noCandidateCount")}</span>
                </div>
              )} */}
            </div>
            <p tw="text-xs text-gray-500 truncate">
              {jobAd.creationDate && `${formatDate(new Date(jobAd.creationDate))}`}
              {jobAd.expirationDate && ` - ${formatDate(new Date(jobAd.expirationDate))}`}
            </p>
          </div>

          <div tw="flex items-center justify-between mt-2 space-x-2">
            <div tw="flex">
              <BriefcaseIcon
                tw="flex-shrink-0 mr-1.5 h-3.5 w-3.5 text-gray-400"
                aria-hidden="true"
              />
              <p tw="text-xs text-gray-500 truncate">{capitalize(jobAd?.organizationName)}</p>
            </div>
            {jobAd.location?.city && (
              <div tw="flex">
                <LocationMarkerIcon
                  tw="flex-shrink-0 mr-1.5 h-3.5 w-3.5 text-gray-400"
                  aria-hidden="true"
                />
                <p tw="text-xs text-gray-500 truncate">{jobAd.location?.city}</p>
              </div>
            )}
          </div>
        </ListItemContent>
      </ListItemLink>
    </ListItem>
  );
};

/** @type {React.FC<{ status: import("./JobAdAPI").JobAdStatus }>} */
export const JobAdStatusBadge = ({ status }) => {
  const { t } = useTranslation();
  const color = getJobAdStatusColor(status);
  return (
    <Badge color={color}>
      <BadgeDot />
      {t(`job-ad-status.${status}`)}
    </Badge>
  );
};

/** @type {import("react").FC} */
const JobAdListItemSkeleton = () => {
  return (
    <ListItem>
      <ListItemContent>
        <div tw="flex-1 min-w-0">
          <Skeleton tw="w-48" />
          <Skeleton tw="w-32" />
        </div>
      </ListItemContent>
    </ListItem>
  );
};

const JobAdList = () => {
  const { t } = useTranslation();
  const role = useRole();
  const hasOwnerEnabled = role === "ORGANIZATION_ADMIN";
  const { page = 1, size = 10, adStatus, owner } = useSearchParams();
  const [selected, setSelected] = useState();
  const queryObj = { page: page - 1, size, status: [adStatus] };

  if (!adStatus || adStatus.length === 0) {
    delete queryObj.status;
  }

  const { status: statusQuery, data, refetch } = useJobAdSchoolQuery(queryObj);
  const matchesListRoute = useRouteMatch("/job-ads");

  const organizationType = localStorage.getItem("organizationType");

  const searchParams = useSearchParams();
  const history = useHistory();
  let jobAdsStatus = [
    "",
    "PUBLISHED",
    "DRAFT",
    "EXPIRED",
    "DISABLED",
    "PUBLICATION_REQUEST",
    "REJECTED",
  ];

  const { data: dataCountByStatus } = useJobAdCountByStatus({ type: "SCHOOL", owner: owner });
  let totalElements = 0;

  if (dataCountByStatus) {
    jobAdsStatus = jobAdsStatus.filter(
      (s) =>
        (dataCountByStatus[s] && dataCountByStatus[s] > 0) || dataCountByStatus[s] === undefined
    );
    for (let attribute in dataCountByStatus) {
      totalElements += dataCountByStatus[attribute];
    }
  }
  return (
    <aside
      tw="flex-shrink-0 border-r border-gray-200 bg-white"
      css={[
        matchesListRoute.isExact
          ? tw`flex flex-col w-full xl:w-96`
          : tw`hidden xl:w-96 xl:order-first xl:flex xl:flex-col`,
      ]}
    >
      <div tw="px-4 sm:px-6 py-5 flex items-start justify-between space-x-3">
        <div tw="space-y-1">
          <h2 tw="text-lg leading-6 font-medium text-gray-900">
            {t("job-ads.search-directory-of-job-ads", {
              count: data ? data.totalElements : Number(size),
            })}
          </h2>
          <div tw="flex flex-wrap gap-2">
            {jobAdsStatus.map((statusParam, index) => (
              <StatusButton
                key={index}
                active={
                  (!adStatus && statusParam === "") ||
                  (adStatus === "" && statusParam === "") ||
                  adStatus === statusParam
                }
                onClick={() => {
                  if (statusParam && statusParam.length > 0) {
                    delete searchParams.page;
                    history.push(
                      `${history.location.pathname}${qs.stringify(
                        {
                          ...searchParams,
                          adStatus: statusParam,
                        },
                        { addQueryPrefix: true }
                      )}`
                    );
                  } else {
                    if (searchParams.adStatus) {
                      delete searchParams.adStatus;
                      delete searchParams.page;
                      history.push(
                        `${history.location.pathname}${qs.stringify(
                          {
                            ...searchParams,
                          },
                          { addQueryPrefix: true }
                        )}`
                      );
                    }
                  }
                }}
              >
                <span>
                  {statusParam === ""
                    ? t("job-ad-status.ALL")
                    : t(`job-ad-status.${statusParam}-plurial`)}
                </span>

                {statusParam === "" ? (
                  <StatusRing text={totalElements ? totalElements : ""} />
                ) : (
                  <StatusRing
                    text={
                      dataCountByStatus && dataCountByStatus[statusParam] !== undefined
                        ? dataCountByStatus[statusParam]
                        : ""
                    }
                  />
                )}
              </StatusButton>
            ))}
          </div>
        </div>
      </div>
      {/* Directory list */}
      <div tw="flex-1 min-h-0 relative overflow-y-auto border-t border-gray-200">
        {statusQuery === "error" && (
          <EmptyState>
            <EmptyStateTitle as="h3">{t("job-ads.failed-to-fetch-job-ads")}</EmptyStateTitle>
            <EmptyStateDescription>
              {t(
                "job-ads.we-could-not-fetch-your-job-ads-right-now-make-sure-that-you-are-connected-to-the-internet-or-try-again-later"
              )}
            </EmptyStateDescription>
            <Button onClick={refetch} tw="mt-8">
              {t("job-ads.try-again")}
            </Button>
          </EmptyState>
        )}
        {/* Loading skeleton state */}
        {statusQuery === "loading" && (
          <List>
            {Array.from({ length: 10 }, (_, index) => (
              <JobAdListItemSkeleton key={index} />
            ))}
          </List>
        )}
        {/* Empty state */}
        {statusQuery === "success" && data.content.length === 0 && (
          <EmptyState>
            <EmptyStateTitle as="h3">{t("job-ads.you-dont-have-any-job-ad-yet")}</EmptyStateTitle>
            {organizationType !== "SCHOOL" && (
              <>
                <EmptyStateDescription>
                  {t("job-ads.you-can-create-your-first-job-ad-by-clicking-on-the-button-below")}
                </EmptyStateDescription>
                <Button as={Link} to="/job-ads/new" tw="mt-8">
                  {t("shared.create")}
                </Button>
              </>
            )}
          </EmptyState>
        )}
        {/* List with job ads */}
        {statusQuery === "success" && data.content.length !== 0 && (
          <List>
            {data?.content.map((jobAd) => (
              <JobAdListItem
                key={jobAd.uuid}
                jobAd={jobAd}
                selected={selected}
                clickCallback={setSelected}
              />
            ))}
          </List>
        )}
      </div>
      <Pagination
        page={Number(page)}
        pageSize={Number(size)}
        totalCount={data ? data.totalElements : Number(size)}
      />
    </aside>
  );
};

const JobAdListRoute = () => {
  const { t } = useTranslation();
  return (
    <>
      <Helmet title={t("job-ads.job-ads")} />
      <JobAdList />
    </>
  );
};

export default JobAdListRoute;
