/** @jsxImportSource @emotion/react */
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import "twin.macro";
import { Empty } from "../shared/Empty";
import { getDrivingLicences } from "./CandidateAPI";

/** @type {React.FC<{ driverLicences: import("./CandidateAPI").DriverLicences, highlightedLicences?: string[], highlightRequiresVehicle?: boolean }>} */
export const CandidateDriverInformation = ({
  driverLicences,
  highlightedLicences,
  highlightRequiresVehicle,
}) => {
  const { t } = useTranslation();
  if (!driverLicences) {
    return <Empty />;
  }
  const drivingLicences = getDrivingLicences(driverLicences);
  return (
    <>
      {drivingLicences.length > 0 && (
        <>
          {t("candidates.driving-licence", { count: drivingLicences.length })}{" "}
          {drivingLicences.map((drivingLicence, index) => (
            <Fragment key={index}>
              {index !== 0 && <span tw="relative">{", "}</span>}
              <span tw="relative">
                {highlightedLicences && highlightedLicences.includes(drivingLicence) && (
                  <span tw="absolute bg-green-100 -left-0.5 -right-0.5 -top-0.5 -bottom-0.5 rounded" />
                )}
                <span tw="relative">{drivingLicence}</span>
              </span>
            </Fragment>
          ))}
          {" - "}
        </>
      )}
      <span tw="relative">
        {driverLicences.hasVehicle && highlightRequiresVehicle && (
          <span tw="absolute bg-green-100 -left-0.5 -right-0.5 -top-0.5 -bottom-0.5 rounded" />
        )}
        <span tw="relative">
          {driverLicences.hasVehicle
            ? t("candidates.has-a-vehicle")
            : t("candidates.has-no-vehicle")}
        </span>
      </span>
    </>
  );
};
