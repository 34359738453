import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import tw from "twin.macro";

export const List = styled("ul")(tw`divide-y divide-gray-200`);
export const ListItem = styled("li")(({ active }) => active && tw`bg-primary-100`);
export const ListItemLink = styled(Link)(
  tw`block hover:bg-gray-50`,
  ({ active }) => active && tw`hover:bg-primary-100`
);
export const ListItemContent = styled("div")(tw`px-6 py-5 flex items-center`);
