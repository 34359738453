/** @jsxImportSource @emotion/react */
import { useCallback, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "twin.macro";
import { PrimaryButton } from "./Button";
import {
  EmptyState,
  EmptyStateDescription,
  EmptyStateIllustration,
  EmptyStateTitle,
} from "./EmptyState";

const ServerDownIllustration = (props) => {
  return (
    <svg viewBox="0 0 1119.6 699" {...props}>
      <circle cx="292.6" cy="213" r="213" tw="fill-current text-gray-200" />
      <path tw="fill-current text-gray-800" d="M0 51.1c0 77.5 48.6 140.3 108.7 140.3" />
      <path
        tw="fill-current text-primary-500"
        d="M108.7 191.4C108.7 113 163 49.6 230 49.6M39.4 58.2c0 73.6 31 133.2 69.3 133.2"
      />
      <path tw="fill-current text-gray-800" d="M108.7 191.4c0-100.2 62.7-181.2 140.2-181.2" />
      <path
        d="M136.8 211.3c-17.4.1-40.4-2.7-45-5.6-3.6-2.1-5-9.8-5.5-13.4h-.5s1 12.5 5.6 15.3 27.7 5.7 45.1 5.5c5 0 6.8-1.8 6.7-4.4-.7 1.6-2.6 2.6-6.4 2.6z"
        opacity=".2"
      />
      <ellipse cx="198.6" cy="424.5" tw="fill-current text-gray-700" rx="187" ry="25.4" />
      <ellipse cx="198.6" cy="424.5" opacity=".1" rx="157" ry="21.4" />
      <ellipse cx="836.6" cy="660.5" tw="fill-current text-gray-700" rx="283" ry="38.5" />
      <ellipse cx="310.6" cy="645.5" tw="fill-current text-gray-700" rx="170" ry="23.1" />
      <path
        fill="none"
        tw="stroke-current text-gray-800"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M462.6 626c90 23 263-30 282-90M309.6 259s130-36 138 80-107 149-17 172M184 537.3s39-10.8 41.5 24-32.2 44.8-5.1 51.7"
      />
      <path
        tw="fill-current text-gray-800"
        d="M778.7 563.2l-7.9 50.3s-38.8 20.6-11.5 21.2 155.7 0 155.7 0 24.9 0-14.5-21.8l-7.9-52.7z"
      />
      <path
        d="M753.8 634.2c6.2-5.5 17-11.3 17-11.3l7.9-50.2h114l7.8 49.6c9.2 5.1 14.9 9 18.2 12 5-1.1 10.6-5.4-18.2-21.4l-7.9-52.7-113.9 3-7.9 50.3s-32.6 17.3-17 20.7z"
        opacity=".1"
      />
      <rect
        width="513.3"
        height="357.5"
        x="578.4"
        y="212.7"
        tw="fill-current text-gray-800"
        rx="18"
      />
      <path tw="fill-current text-gray-700" d="M595.7 231.8h478.7v267.8H595.7z" />
      <circle cx="835.1" cy="223.3" r="3" tw="fill-current text-gray-200" />
      <path
        tw="fill-current text-gray-800"
        d="M1091.7 520.8v31.4a18 18 0 01-18 18H596.4a18 18 0 01-18-18v-31.4zM969 667.5v6H643v-4.8l.4-1.2 8.1-21.8h310.8l6.7 21.8zM1094.4 661.5c-.6 2.6-2.8 5.3-7.9 7.8-18.1 9-55.1-2.4-55.1-2.4s-28.5-4.9-28.5-17.6a22.7 22.7 0 012.5-1.5c7.7-4 33-14 78 .4a18.7 18.7 0 018.5 5.6c1.8 2.2 3.2 4.9 2.5 7.7z"
      />
      <path
        d="M1094.4 661.5c-22.2 8.6-42 9.2-62.4-5a44.7 44.7 0 00-26.6-8.7c7.7-4 33-14 78 .4a18.7 18.7 0 018.5 5.6c1.8 2.2 3.2 4.9 2.5 7.7z"
        opacity=".1"
      />
      <ellipse cx="1066.5" cy="654.1" tw="fill-current text-gray-200" rx="7.9" ry="2.4" />
      <circle cx="835.1" cy="545.7" r="11.5" tw="fill-current text-gray-200" />
      <path d="M969 667.5v6H643v-4.8l.4-1.2H969z" opacity=".1" />
      <path tw="fill-current text-gray-800" d="M108.6 159h208v242h-208z" />
      <path
        tw="fill-current text-gray-700"
        d="M87.6 135h250v86h-250zM87.6 237h250v86h-250zM87.6 339h250v86h-250z"
      />
      <path tw="fill-current text-primary-500" d="M271.6 150h16v16h-16z" opacity=".4" />
      <path tw="fill-current text-primary-500" d="M294.6 150h16v16h-16z" opacity=".8" />
      <path tw="fill-current text-primary-500" d="M317.6 150h16v16h-16z" />
      <path tw="fill-current text-primary-500" d="M271.6 251h16v16h-16z" opacity=".4" />
      <path tw="fill-current text-primary-500" d="M294.6 251h16v16h-16z" opacity=".8" />
      <path tw="fill-current text-primary-500" d="M317.6 251h16v16h-16z" />
      <path tw="fill-current text-primary-500" d="M271.6 352h16v16h-16z" opacity=".4" />
      <path tw="fill-current text-primary-500" d="M294.6 352h16v16h-16z" opacity=".8" />
      <path tw="fill-current text-primary-500" d="M317.6 352h16v16h-16z" />
      <circle cx="316.6" cy="538" r="79" tw="fill-current text-gray-800" />
      <path tw="fill-current text-gray-800" d="M280.6 600h24v43h-24zM328.6 600h24v43h-24z" />
      <ellipse cx="300.6" cy="643.5" tw="fill-current text-gray-800" rx="20" ry="7.5" />
      <ellipse cx="348.6" cy="642.5" tw="fill-current text-gray-800" rx="20" ry="7.5" />
      <circle cx="318.6" cy="518" r="27" tw="fill-current text-white" />
      <circle cx="318.6" cy="518" r="9" tw="fill-current text-gray-700" />
      <path
        tw="fill-current text-primary-500"
        d="M240 464.5c-6.4-28.5 14-57.4 45.5-64.4s62.3 10.4 68.7 39-14.6 39-46 46-61.8 8-68.2-20.6z"
      />
      <ellipse
        cx="417.2"
        cy="611.3"
        tw="fill-current text-gray-800"
        rx="39.5"
        ry="12.4"
        transform="rotate(-23.2 156.4 637.7)"
      />
      <ellipse
        cx="269.2"
        cy="664.3"
        tw="fill-current text-gray-800"
        rx="39.5"
        ry="12.4"
        transform="rotate(-23.2 8.4 690.7)"
      />
      <path
        tw="fill-current text-white"
        d="M362.6 561c0 7.7-19.9 23-42 23s-43-14.3-43-22 21-6 43-6 42-2.7 42 5z"
      />
    </svg>
  );
};

/** @type {React.FC<{ error: Error }>} */
export const ErrorPage = ({ error, onReset }) => {
  const { t } = useTranslation();
  const [isErrorExpanded, setIsErrorExpanded] = useState(false);
  const toggleErrorExpanded = useCallback(() => {
    setIsErrorExpanded((isErrorExpanded) => !isErrorExpanded);
  }, []);
  return (
    <>
      <Helmet title="Error" />
      <EmptyState tw="w-full">
        <EmptyStateIllustration as={ServerDownIllustration} />
        <EmptyStateTitle>{t("shared.whoops-there-was-an-error")}</EmptyStateTitle>
        <EmptyStateDescription>
          {t(
            "shared.we-encountered-an-issue-while-trying-to-load-a-page-or-perform-an-action-you-can-try-again-later-or-send-the-following-technical-information-to-the-support-team"
          )}
        </EmptyStateDescription>

        <div tw="relative max-w-7xl w-full text-xs text-gray-500 font-semibold mt-4 font-mono bg-gray-200 rounded-lg px-4 py-3">
          <button
            tw="absolute top-2.5 right-4 bg-gray-300 text-gray-800 font-bold bg-opacity-75 py-0.5 px-2 rounded-md"
            onClick={toggleErrorExpanded}
          >
            {isErrorExpanded ? t("shared.view-less") : t("shared.view-more")}
          </button>
          {isErrorExpanded ? (
            <pre tw="overflow-x-scroll">{error.stack}</pre>
          ) : (
            <span tw="overflow-x-scroll">{error.message}</span>
          )}
        </div>

        <PrimaryButton as={Link} to="/" onClick={onReset} tw="mt-8">
          {t("shared.go-back-to-dashboard")}
        </PrimaryButton>
      </EmptyState>
    </>
  );
};
