/** @jsxImportSource @emotion/react */
import "twin.macro";
import tw from "twin.macro";
import { useTranslation } from "react-i18next";

export const JobAdMatchingScore = ({ score, ...props }) => {
  const displayedScore = score || 0;
  return (
    <div
      tw="flex-shrink-0 h-10 w-10 flex items-center justify-center ring ring-gray-500 text-gray-500 rounded-full overflow-hidden"
      css={[
        displayedScore <= 70 && displayedScore > 50 && tw`ring-primary-500 text-primary-500`,
        displayedScore > 70 && tw`ring-green-500 text-green-500`,
      ]}
      {...props}
    >
      <span tw="text-sm font-semibold leading-none">{displayedScore}%</span>
    </div>
  );
};

export const JobAdMatchingScoreBadge = ({ icon, label, score, ...props }) => {
  const displayedScore = score || 0;
  const { t } = useTranslation();  
  return (
    <div
      tw="flex items-center px-2.5 py-0.5 rounded-full text-xs bg-gray-600 text-white svg:(h-4 w-4 mr-1.5)"
      css={[
        displayedScore <= 70 && displayedScore > 50 && tw`bg-primary-500 text-white`,
        displayedScore > 70 && tw`bg-green-500 text-white`,
      ]}
      {...props}
    >
      {icon}
      <span>{label}</span>
      <span tw="font-semibold ml-1"> {displayedScore > 0 ? displayedScore+"%" : t("job-ads.not-provided")}</span>
    </div>
  );
};
