// @ts-check
import { useQueryClient, useQuery } from "react-query";
import { useSkillAPI } from "../shared/useAPI";
import { useLanguage } from "../shared/useLanguage";

/**
 * @typedef CloudSearchJobFields
 * @property {string} code The job code (e.g.: "D130100")
 * @property {string} id The job id
 * @property {string} name The job name
 * @property {string} libelleen
 * @property {string} libellees
 * @property {string} libellefr
 * @property {string} libellept
 */

const cloudSearchHitTitleMapping = {
  FR: "libellefr",
  EN: "libelleen",
  DE: "libellede",
  NL: "libellenl",
  ES: "libellees",
  IT: "libelleit",
  PT: "libellept",
};

/** @type {(cloudSearchHit: CloudSearchJobFields, language: import("../shared/useLanguage").Language) => string} */
const resolveCloudSearchHitTitle = (cloudSearchHit, language) => {
  const property = cloudSearchHitTitleMapping[language];
  return cloudSearchHit[property] || cloudSearchHit.libelleen || cloudSearchHit.name;
};

/** @type {() => (searchParams: { input: string, lang: import("../shared/useLanguage").Language }) => Promise<CloudSearchJobFields[]>} */
export const useFetchJobs = () => {
  const queryClient = useQueryClient();
  const skillAPI = useSkillAPI();

  return (searchParams) => {
    return queryClient.fetchQuery(["public/skill/jobesautocomplete", searchParams], async () => {
      const jobs = await skillAPI.get("public/skill/jobesautocomplete", { searchParams }).json();
      // Replace job suggestion name with the localized job title
      const translatedJobs = jobs.map((job) => ({
        ...job,
        name: resolveCloudSearchHitTitle(job, searchParams?.lang || "EN"),
      }));
      return translatedJobs;
    });
  };
};

/**
 * @typedef Job
 * @property {string} code - e.g.: "F170312"
 * @property {number} id - e.g.: 3228
 * @property {string} libelleDe - e.g.: null
 * @property {string} libelleEn - e.g.: "Bricklayer / Mason"
 * @property {string} libelleEs - e.g.: "Albañil"
 * @property {string} libelleFr - e.g.: "Maçon / Maçonne"
 * @property {string} libelleIt - e.g.: null
 * @property {string} libelleNl - e.g.: null
 * @property {string} libellePt - e.g.: "Tijolo / Pedreiro"
 * @property {string} name - e.g.: "Maçon / Maçonne"
 * @property {number} priority - e.g.: 2000
 * @property {number} specialityId - e.g.: 171
 */

/** @type {() => (jobCode: string) => Promise<Job>} */
export const useFetchJobByCode = () => {
  const queryClient = useQueryClient();
  const skillAPI = useSkillAPI();

  return (jobCode) => {
    return queryClient.fetchQuery(`api/skill/candidate/job/byCode/${jobCode}`, async () => {
      return skillAPI.get(`api/skill/candidate/job/byCode/${jobCode}`).json();
    });
  };
};

export const useFetchJobsByJobCodes = (jobCodes) => {
  const api = useSkillAPI();
  return useQuery({
    queryKey: [`api/skill/candidate/jobs/byCodes`, jobCodes],
    queryFn: async () => api.post(`api/skill/candidate/jobs/byCodes`, { json: jobCodes }).json(),
    enabled: !!jobCodes,
  });
};

const jobTitleMapping = {
  FR: "libelleFr",
  EN: "libelleEn",
  DE: "libelleDe",
  NL: "libelleNl",
  ES: "libelleEs",
  IT: "libelleIt",
  PT: "libellePt",
};

/** @type {(job: Job, language: import("../shared/useLanguage").Language) => string} */
export const resolveJobTitle = (job, language) => {
  const property = jobTitleMapping[language];
  return job[property] || job.libelleEn || job.name;
};

/** @type {() => (jobCode: string) => Promise<string>} */
export const useFetchTranslatedJobTitleByJobCode = () => {
  const language = useLanguage();
  const fetchJobByCode = useFetchJobByCode();
  return async (jobCode) => {
    try {
      return resolveJobTitle(await fetchJobByCode(jobCode), language);
    } catch (err) {
      return null;
    }
  };
};
