/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import { MailIcon, PhoneIcon } from "@heroicons/react/solid";
import { useTranslation } from "react-i18next";
import "twin.macro";
import tw from "twin.macro";
import { CandidateActionBar } from "../candidates/CandidateActionBar";
import { useCandidateByIdQuery } from "../candidates/CandidateAPI";
import { CandidateAvailabilities } from "../candidates/CandidateAvailabilities";
import { CandidateDetailsSkeleton } from "../candidates/CandidateDetails";
import { CandidateDriverInformation } from "../candidates/CandidateDriverInformation";
import { CandidateLocation } from "../candidates/CandidateLocation";
import { CandidateTitle } from "../candidates/CandidateTitle";
import { ExperienceList } from "../candidates/ExperienceList";
import { LanguageList } from "../candidates/LanguageList";
import { QuestionnaireResultList } from "../candidates/QuestionnaireResultList";
import { SkillList } from "../candidates/SkillList";
import { diplomaLabel } from "../diplomas/diplomas";
import NotFoundRoute from "../not-found/NotFoundRoute";
import { useCompaniesLinkedQuery } from "../school/SchoolAPI";
import { Avatar } from "../shared/Avatar";
import { Container } from "../shared/Container";
import {
  DescriptionList,
  DescriptionTerm,
  DescriptionTermLabel,
  DescriptionTermValue
} from "../shared/DescriptionList";
import { Empty } from "../shared/Empty";
import { ErrorPage } from "../shared/ErrorPage";
import { PaginationWithNumber } from "../shared/PaginationWithNumber";
import { Skeleton } from "../shared/Skeleton";
import { useFormatDate } from "../shared/useFormatDate";
import { useSearchParams } from "../shared/useSearchParams";

const Companies = () => {
  // Get jobAds with paging
  const { page = 1, size = 10 } = useSearchParams();
  const { t } = useTranslation();
  const { status, data } = useCompaniesLinkedQuery({
    page: page - 1,
    size,
  });

  const studentPlurialOrSingular = () => {
    if (data.totalElements < 2) {
      return t("home.company");
    } else {
      return t("home.companies");
    }
  };
  //Number of items mapped during the loading
  const loadingNumberOfCompanies = 10;

  return (
    <div tw="flex-col lg:flex w-full overflow-auto">
      {status === "loading" && (
        <div tw="py-10 px-10 w-full">
          <div tw="flex flex-col">
            <div tw="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div tw="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <h2 tw="text-lg leading-6 font-medium text-gray-900 mb-5">
                  <Skeleton />
                </h2>
                <div tw="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table tw="min-w-full divide-y divide-gray-200">
                    <thead tw="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          tw="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          {t("schoolOrganization.company-name")}
                        </th>
                        <th
                          scope="col"
                          tw="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          {t("schoolOrganization.company-location")}
                        </th>
                        <th
                          scope="col"
                          tw="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          {t("schoolOrganization.company-jobadnumber")}
                        </th>
                      </tr>
                    </thead>
                    <tbody tw="bg-white divide-y divide-gray-200">
                      {[...Array(loadingNumberOfCompanies)].map((e, index) => (
                        <tr key={index}>
                          <td tw="px-6 py-4 whitespace-nowrap">
                            <div tw="flex items-center">
                              <div tw="flex-shrink-0 h-10 w-10">
                                <div tw="flex justify-center px-4">
                                  <Avatar tw="h-10 w-10 rounded-full bg-green-300" user="JB" />
                                </div>
                              </div>
                              <div tw="ml-4">
                                <div tw="text-sm font-medium text-gray-900">
                                  <Skeleton />
                                </div>
                              </div>
                            </div>
                          </td>
                          <td tw="px-6 py-4 whitespace-nowrap">
                            <div tw="text-sm text-gray-900">
                              {" "}
                              <Skeleton />
                            </div>
                          </td>
                          <td tw="px-6 py-4 whitespace-nowrap">
                            <span tw="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                              <Skeleton />
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <PaginationWithNumber
                    page={Number(page)}
                    pageSize={Number(size)}
                    totalCount={data ? data.totalElements : Number(size)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {status === "success" && data.content.length !== 0 && (
        <>
          <div tw="py-10 px-10 w-full">
            <div tw="flex flex-col">
              <div tw="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div tw="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <h2 tw="text-lg leading-6 font-medium text-gray-900 mb-5">
                    {data.totalElements} {studentPlurialOrSingular()}
                  </h2>
                  <div tw="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table tw="min-w-full divide-y divide-gray-200">
                      <thead tw="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            tw="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            {t("schoolOrganization.company-name")}
                          </th>
                          <th
                            scope="col"
                            tw="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            {t("schoolOrganization.company-location")}
                          </th>
                          <th
                            scope="col"
                            tw="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            {t("schoolOrganization.company-jobadnumber")}
                          </th>
                        </tr>
                      </thead>
                      <tbody tw="bg-white divide-y divide-gray-200">
                        {data &&
                          data.content.map((jobAd, index) => (
                            <tr key={index}>
                              <td tw="px-6 py-4 whitespace-nowrap">
                                <div tw="flex items-center">
                                  <div tw="flex-shrink-0 h-10 w-10">
                                    <img
                                      tw="w-auto h-10 rounded-full z-50"
                                      src={`${process.env.REACT_APP_DEV_PUBLIC_MEDIA_URL}/organization/logo/original/${jobAd.company.organizationUniqueUrlName}.jpeg`}
                                      alt={jobAd.organizationUniqueUrlName}
                                      onError={(event) => (event.target.style.display = "none")}
                                    />
                                  </div>
                                  <div tw="ml-4">
                                    <div tw="text-sm font-medium text-gray-900">
                                      {jobAd.company.organizationName}
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td tw="px-6 py-4 whitespace-nowrap">
                                <div tw="text-sm text-gray-900">
                                  {jobAd.company.location ? (
                                    jobAd.company.location
                                  ) : (
                                    <div tw="text-sm text-gray-500">n.c</div>
                                  )}
                                </div>
                              </td>
                              <td tw="px-6 py-4 whitespace-nowrap">
                                <span tw="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                  {jobAd.jobAdNumber}
                                </span>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    <PaginationWithNumber
                      page={Number(page)}
                      pageSize={Number(size)}
                      totalCount={data ? data.totalElements : Number(size)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Companies;

const Section = styled("section")(tw`space-y-1`);
const SectionTitle = styled("h2")(tw`text-sm font-medium text-gray-500`);

export const DetailsStudent = ({ showResults, setShowResults, currentSudent }) => {
  const { t } = useTranslation();
  const formatDate = useFormatDate({ dateStyle: "medium" });
  const { status: statusData, data, error } = useCandidateByIdQuery(currentSudent);
  return (
    <div
      tw="fixed right-0 top-0 bottom-0 z-[100] max-w-3xl shadow-md border-l border-gray-200 bg-white overflow-y-auto"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      {/* Loading state */}
      {statusData === "loading" && <CandidateDetailsSkeleton />}
      {/* Error state */}
      {statusData === "error" && <ErrorPage error={error} />}
      {/* Empty state */}
      {statusData === "success" && !data && <NotFoundRoute />}
      {/* Details */}
      {statusData === "success" && data && (
        <div tw="block">
          <div
            tw="fixed inset-0"
            aria-hidden="true"
            onClick={() => setShowResults(!showResults)}
          ></div>
          <div tw="inline-block bg-white text-left overflow-hidden transform transition-all h-full">
            {/* Profile header */}
            <header tw="bg-white border-b border-gray-200">
              <Container tw="py-4">
                <div tw="flex flex-col gap-4 sm:(flex-row items-center)">
                  {/* Candidate avatar */}
                  <Avatar
                    tw="text-4xl h-24 w-24 ring-4 ring-white mx-auto sm:(h-32 w-32 mx-0)"
                    user={data.candidate}
                  />

                  {/* Candidate title */}
                  <div tw="flex-1 flex-shrink-0 sm:w-2/5">
                    <h1 tw="text-2xl font-bold text-gray-900 text-center break-words sm:(text-left)">
                      {data.candidate.firstname} {data.candidate.lastname}
                    </h1>
                    <p tw="text-sm font-medium text-gray-500 text-center break-words sm:(text-left)">
                      <CandidateTitle candidateDetails={data.candidateDetails} />
                    </p>
                    <p tw="text-sm font-medium text-gray-500 text-center break-words sm:(text-left)">
                      {t("schoolOrganization.student")}
                    </p>
                  </div>

                  {/* Candidate actions */}
                  <div tw="flex flex-col gap-3 sm:(flex-col items-end)">
                    <CandidateActionBar 
                        candidate={data.candidate}
                        candidateDetails={data.candidateDetails} />
                  </div>
                </div>
              </Container>
            </header>

            <Container tw="space-y-8 pb-12 pt-6">
              {/* Description list */}
              <Section>
                <DescriptionList>
                  <div tw="flex space-x-4 justify-between items-center">
                    <DescriptionTerm>
                      <DescriptionTermLabel>{t("candidates.phone")}</DescriptionTermLabel>
                      <DescriptionTermValue>
                        {data.candidate.phone?.number || <Empty />}
                      </DescriptionTermValue>
                    </DescriptionTerm>
                    {data.candidate.phone?.number && (
                      <a
                        href={`tel:${data.candidate.phone?.number}`}
                        target="_blank"
                        rel="noreferrer"
                        tw="inline-flex items-center shadow-sm px-2.5 py-1 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50"
                      >
                        <PhoneIcon tw="text-gray-400 h-4 w-4" />
                        <span tw="sr-only">{t("candidates.call")}</span>
                      </a>
                    )}
                  </div>
                  <div tw="flex space-x-4 justify-between items-center">
                    <DescriptionTerm>
                      <DescriptionTermLabel>{t("candidates.email")}</DescriptionTermLabel>
                      <DescriptionTermValue>
                        {data.candidate.email || <Empty />}
                      </DescriptionTermValue>
                    </DescriptionTerm>
                    {data.candidate.email && (
                      <a
                        href={`mailto:${data.candidate.email}`}
                        target="_blank"
                        rel="noreferrer"
                        tw="inline-flex items-center shadow-sm px-2.5 py-1 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50"
                      >
                        <MailIcon tw="text-gray-400 h-4 w-4" />
                        <span tw="sr-only">{t("candidates.send-email")}</span>
                      </a>
                    )}
                  </div>
                  <DescriptionTerm>
                    <DescriptionTermLabel>{t("candidates.title")}</DescriptionTermLabel>
                    <DescriptionTermValue>
                      <CandidateTitle candidateDetails={data.candidateDetails} />
                    </DescriptionTermValue>
                  </DescriptionTerm>
                  <DescriptionTerm>
                    <DescriptionTermLabel>{t("candidates.location")}</DescriptionTermLabel>
                    <DescriptionTermValue>
                      <CandidateLocation candidateDetails={data.candidateDetails} />
                    </DescriptionTermValue>
                  </DescriptionTerm>
                  <DescriptionTerm>
                    <DescriptionTermLabel>
                      {t("candidates.driver-information")}
                    </DescriptionTermLabel>
                    <DescriptionTermValue>
                      <CandidateDriverInformation
                        driverLicences={data.candidateDetails?.driverLicences}
                      />
                    </DescriptionTermValue>
                  </DescriptionTerm>
                  <DescriptionTerm>
                    <DescriptionTermLabel>{t("candidates.diploma")}</DescriptionTermLabel>
                    <DescriptionTermValue>
                      {data.candidateDetails.lastDiploma ? (
                        t(diplomaLabel[data.candidateDetails.lastDiploma])
                      ) : (
                        <Empty />
                      )}
                    </DescriptionTermValue>
                  </DescriptionTerm>
                  <DescriptionTerm>
                    <DescriptionTermLabel>{t("candidates.birthday")}</DescriptionTermLabel>
                    <DescriptionTermValue>
                      {data.candidate.birthdate ? (
                        formatDate(new Date(data.candidate.birthdate))
                      ) : (
                        <Empty />
                      )}
                    </DescriptionTermValue>
                  </DescriptionTerm>
                </DescriptionList>
              </Section>

              {/* Experience list */}
              {data.candidateDetails.experiences !== undefined &&
                data.candidateDetails.experiences.length > 0 && (
                  <Section>
                    <SectionTitle>{t("candidates.experiences")}</SectionTitle>
                    <ExperienceList
                      experiences={[...data.candidateDetails.experiences].reverse()}
                      empty={t("candidates.empty-experiences")}
                    />
                  </Section>
                )}

              {/* Skill list */}
              <Section>
                <SectionTitle>{t("candidates.skills")}</SectionTitle>
                <SkillList
                  skills={data.candidateDetails.candidateSkills?.skills}
                  empty={t("candidates.empty-skills")}
                />
              </Section>

              {/* Activity list */}
              <Section>
                <SectionTitle>{t("candidates.activities")}</SectionTitle>
                <SkillList
                  skills={data.candidateDetails.candidateSkills?.activities}
                  empty={t("candidates.empty-activities")}
                />
              </Section>

              {/* Accreditation list */}
              {data.candidateDetails.candidateSkills?.accreditations && (
                <Section>
                  <SectionTitle>{t("candidates.accreditations")}</SectionTitle>
                  <SkillList
                    skills={data.candidateDetails.candidateSkills?.accreditations}
                    empty={t("candidates.empty-accreditations")}
                  />
                </Section>
              )}

              {/* Questionnaire result list */}
              <Section>
                <SectionTitle>{t("candidates.qualities")}</SectionTitle>
                <QuestionnaireResultList
                  candidateId={data.candidate.uuid}
                  empty={t("candidates.empty-qualities")}
                />
              </Section>

              {/* Questionnaire result list */}

              {data.candidateDetails.spokenLanguages !== undefined &&
                data.candidateDetails.spokenLanguages.length > 0 && (
                  <Section>
                    <SectionTitle>{t("candidates.languages")}</SectionTitle>
                    <LanguageList
                      languages={data.candidateDetails.spokenLanguages}
                      empty={t("candidates.empty-languages")}
                    />
                  </Section>
                )}

              {/* Candidate availabilities */}
              <Section>
                <SectionTitle>{t("candidates.availabilities")}</SectionTitle>
                <CandidateAvailabilities
                  availabilities={data.candidateDetails.availabilities}
                  empty={t("candidates.empty-availabilities")}
                />
              </Section>
            </Container>
          </div>
        </div>
      )}
    </div>
  );
};
