/** @jsxImportSource @emotion/react */
import { useTranslation } from "react-i18next";
import "twin.macro";
import tw from "twin.macro";
import { languageLabel, languageLevelLabel } from "../languages/languages";
import { EmptyState, EmptyStateDescription } from "../shared/EmptyState";

/** @type {React.FC<{ language: import("./CandidateAPI").SpokenLanguage, highlighted?: string[] }>} */
const LanguageListItem = ({ language, highlighted }) => {
  const { t } = useTranslation();
  return (
    <li
      tw="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm"
      css={
        highlighted &&
        highlighted.includes(language.languageIsoCode.toUpperCase()) &&
        tw`bg-green-100 border-green-300`
      }
    >
      <p tw="text-sm font-medium text-gray-900">{t(languageLabel[language.languageIsoCode])}</p>
      <p tw="text-sm text-gray-500 truncate">{t(languageLevelLabel[language.level])}</p>
    </li>
  );
};

/** @type {React.FC<{ languages?: import("./CandidateAPI").SpokenLanguage[], empty: JSX.Element, highlighted?: string[] }>} */
export const LanguageList = ({ languages, empty, highlighted }) => {
  const isEmpty = !languages || languages.length === 0;
  return isEmpty ? (
    <EmptyState tw="bg-gray-100 rounded-lg">
      <EmptyStateDescription tw="mt-0">{empty}</EmptyStateDescription>
    </EmptyState>
  ) : (
    <ul tw="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
      {languages.map((language) => (
        <LanguageListItem
          key={language.languageIsoCode}
          language={language}
          highlighted={highlighted}
        />
      ))}
    </ul>
  );
};
