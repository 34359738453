import styled from "@emotion/styled";
import tw from "twin.macro";

const ButtonBase = styled("button")(
  tw`inline-flex items-center justify-center px-4 py-2 border text-sm font-medium rounded-md`,
  tw`focus:(outline-none ring-2 ring-offset-2 ring-primary-500)`,
  tw`svg:(-ml-1 mr-2 h-5 w-5)`,
  ({ disabled }) => disabled && tw`opacity-50 pointer-events-none`
);

export const PrimaryButton = styled(ButtonBase)(
  tw`border-transparent shadow-sm text-white bg-primary-600`,
  tw`hover:(bg-primary-700)`,
  tw`svg:(text-white)`
);

export const Button = styled(ButtonBase)(
  tw`border-gray-300 shadow-sm text-gray-700 bg-white`,
  tw`hover:(bg-gray-50)`,
  tw`svg:(text-gray-500)`
);

export const ButtonBaseWithoutFocus = styled("button")(
  tw`inline-flex items-center justify-center px-4 py-2 border text-sm font-medium rounded-md border-gray-300 shadow-sm text-gray-700 bg-white`,
  tw`hover:(bg-gray-50)`,
  tw`svg:(-ml-1 mr-2 h-5 w-5 text-gray-500)`,
  ({ disabled }) => disabled && tw`opacity-50 pointer-events-none`
);

const LinkButtonBase = styled("button")(
  tw`text-sm font-medium`,
  ({ disabled }) => disabled && tw`opacity-50 pointer-events-none`
);

export const PrimaryLinkButton = styled(LinkButtonBase)(
  tw`text-primary-600`,
  tw`hover:(text-primary-500)`
);

export const LinkButton = styled(ButtonBase)(
  tw`border-blue-300 shadow-sm text-blue-700 bg-white p-2`,
  tw`hover:(bg-blue-50)`,
  tw`svg:(text-blue-500)`
);

export const StatusButton = styled("button")(
  tw`inline-flex items-center justify-center px-2 py-0.5 rounded-full text-sm font-medium text-gray-800 bg-gray-100 focus:outline-none focus:bg-primary-500 focus:text-white shadow-sm hover:shadow-md`,
  ({ active }) => active && tw`outline-none bg-primary-500 text-white`
);
