import { useTranslation } from "react-i18next";

/** @type {(locale: string) => string} */
const sanitizeLanguage = (locale) => {
  if (!locale) {
    return null;
  }
  const [language] = locale.split("-");
  return language;
};

export const useCometChatLanguage = () => {
  const { i18n } = useTranslation();
  return sanitizeLanguage(i18n.language);
};
