import { useEffect, useRef } from "react";
/** @jsxImportSource @emotion/react */
import "twin.macro";

/** @type {React.FC<React.VideoHTMLAttributes<HTMLVideoElement> & { duration: number }>} */
export const Video = ({
  src,
  duration = process.env.REACT_APP_VIDEO_DURATION / 1000,
  ...props
}) => {
  const videoRef = useRef(null);
  useEffect(() => {
    /** @type {HTMLVideoElement | null} */
    const video = videoRef.current;
    if (video === null) {
      return;
    }
    // Prevent video from going after the given `duration`
    // This is done to avoid trimming the video client-side when using file upload
    // Trimming client-side would require large libraries that could impact performance (i.e. ffmpeg-js)
    // If we want to trim the video, a preferred solution would be to do this server-side and
    // respond with the trimmed video when the client downloads it.
    const listener = () => {
      if (video.currentTime > duration) {
        video.pause();
        video.currentTime = 0;
      }
    };
    video.addEventListener("timeupdate", listener);
    return () => video.removeEventListener("timeupdate", listener);
  });

  useEffect(() => {
    if (videoRef.current && src) {
      videoRef.current.src = src;
    }
  }, [src]);

  return <video tw="block rounded-2xl" ref={videoRef} {...props} />;
};
