import { useTranslation } from "react-i18next";

/** @typedef {"FR" | "EN" | "DE" | "NL" | "ES" | "IT" | "PT"} Language */

/** @type {(locale: string) => string} */
const sanitizeLanguage = (locale) => {
  if (!locale) {
    return null;
  }
  const [language] = locale.split("-");
  return language.toUpperCase();
};

/** @type {() => Language} */
export const useLanguage = () => {
  const { i18n } = useTranslation();
  const language = sanitizeLanguage(i18n.language);
  return ["FR", "EN", "DE", "NL", "ES", "IT", "PT"].includes(language) ? language : "EN";
};
