/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import { InformationCircleIcon } from "@heroicons/react/outline";
import { ChevronDownIcon, XIcon } from "@heroicons/react/solid";
import { createContext, forwardRef, useContext, useMemo } from "react";
import { get, useController, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import ReactSelect from "react-select";
import ReactAsyncSelect from "react-select/async";
import "twin.macro";
import tw, { theme } from "twin.macro";
import { useId } from "./useId";

const FormGroupContext = createContext(null);

/**
 * Form group component exposing a unique `id` using context to its child components.
 * This will also wrap children in a `div` element.
 * Child components can use the `useFormId()` hook to access the unique id value.
 * See `Label` and `Input` for usage example.
 * @type {React.FC<React.HTMLAttributes<HTMLDivElement>>}
 * @example
 * <FormGroup>
 *   <Label>Text field</Label>
 *   <Input name="textField" />
 * </FormGroup>
 * @example
 * <FormGroup>
 *   <Label>Select field</Label>
 *   <Select name="selectField">
 *     <option>Option 1</option>
 *     <option>Option 2</option>
 *   </Select>
 * </FormGroup>
 * @example
 * <FormGroup>
 *   <Label>Textarea field</Label>
 *   <TextArea name="textareaField" />
 * </FormGroup>
 * @example
 * <FormGroup>
 *   <Label>Text field with helper text</Label>
 *   <Input name="textField" />
 *   <FieldHelperText>Some helper text</FieldHelperText>
 * </FormGroup>
 */
export const FormGroup = ({ children, ...props }) => {
  const id = useId();
  const context = useMemo(() => ({ id }), [id]);
  return (
    <FormGroupContext.Provider value={context}>
      <div tw="space-y-1" {...props}>
        {children}
      </div>
    </FormGroupContext.Provider>
  );
};

export const InlineFormGroup = (props) => {
  return <FormGroup tw="space-y-0 space-x-3 flex items-center" {...props} />;
};

/**
 * Return the unique id associated to the current `FormGroup` or `InlineFormGroup`.
 * This value can be used to assign a `label` `htmlFor` prop or an `input` `id`.
 * @type {() => string}
 */
export const useFormId = () => {
  const context = useContext(FormGroupContext);
  if (context === null) {
    throw new Error("`useFormId` should be wrapped withing a `FormGroup`");
  }
  return context.id;
};

/**
 * A form label component. Automatically assigns the `htmlFor` prop using the id of the current `FormGroup`.
 * @type {React.FC<React.LabelHTMLAttributes<HTMLLabelElement>>}
 * @example
 * <FormGroup>
 *   <Label>A label</Label>
 *   <Input name="textField" />
 * </FormGroup>
 */
export const Label = (props) => {
  const id = useFormId();
  return (
    <label
      htmlFor={id}
      tw="block text-sm font-medium text-gray-700"
      css={{
        "input:disabled + &": tw`opacity-50 cursor-not-allowed`,
      }}
      {...props}
    />
  );
};

export const RequiredAsterisk = (props) => {
  return (
    <span tw="text-red-500 font-medium" {...props}>
      *
    </span>
  );
};

export const HelperTextIcon = styled(InformationCircleIcon)(
  tw`w-5 h-5 text-gray-400 flex-shrink-0 mr-1.5`
);
export const HelperText = styled("p")(tw`flex text-sm pt-1 text-gray-500`);

export const useFieldError = (name) => {
  const {
    formState: { errors },
  } = useFormContext();
  const error = get(errors, name);
  return error;
};

export const useObjectFieldError = (name) => {
  // Get object properties errors
  const objectErrors = useFieldError(name);
  if (!objectErrors) {
    return null;
  }
  const objectErrorEntries = Object.entries(objectErrors);
  if (objectErrorEntries.length === 0) {
    return null;
  } else {
    const [, firstError] = objectErrorEntries[0];
    return firstError;
  }
};

export const ErrorMessage = ({ name }) => {
  const { t } = useTranslation();
  const error = useFieldError(name);
  return error ? (
    <HelperText tw="text-red-600" role="alert">
      {error.message || t(`shared.${error.type}`)}
    </HelperText>
  ) : null;
};

export const ErrorWysiwygMessage = ({ name }) => {
  const { t } = useTranslation();
  const error = useFieldError(name);
  return error ? (
    <HelperText tw="text-red-600" role="alert">
      {error.type === "validate" && name === "content" && (t(`shared.error-wysiwyg`))}
      {(error.type !== "validate" || name !== "content") && (error.message || t(`shared.${error.type}`))}
    </HelperText>
  ) : null;
};

export const ObjectErrorMessage = ({ name }) => {
  const { t } = useTranslation();
  const error = useObjectFieldError(name);
  return error ? (
    <HelperText tw="text-red-600" role="alert">
      {error.message || t(`shared.${error.type}`)}
    </HelperText>
  ) : null;
};

/**
 * Input component with optional trailing or leading addon.
 * @type {React.FC<React.InputHTMLAttributes<HTMLInputElement>>}
 * @example
 * <FormGroup>
 *   <Label>Text field</Label>
 *   <Input name="textField" />
 * </FormGroup>
 */
export const InputBase = forwardRef((props, ref) => {
  const id = useFormId();
  return (
    <input
      ref={ref}
      id={id}
      type={props?.type ? props.type : "text"}
      tw="block w-full shadow-sm text-sm border-gray-300 rounded-md focus:(ring-primary-500 border-primary-500)"
      css={{
        '&[aria-invalid="true"]': tw`border-red-300 text-red-900 placeholder-red-300 focus:(ring-red-500 border-red-500)`,
      }}
      {...props}
    />
  );
});

/** @type {React.FC<{ rules?: import("react-hook-form").RegisterOptions } & React.InputHTMLAttributes<HTMLInputElement>>} */
export const Input = ({ rules, name, ...props }) => {
  const { register } = useFormContext();
  const error = useFieldError(name);
  return (
    <InputBase aria-invalid={error ? "true" : "false"} {...register(name, rules)} {...props} />
  );
};

/** @type {React.FC<{ rules?: import("react-hook-form").RegisterOptions } & React.InputHTMLAttributes<HTMLInputElement>>} */
export const NumberInput = ({ rules, ...props }) => {
  return (
    <Input
      type="number"
      rules={{
        ...rules,
        setValueAs: (value) => {
          if (value === null || value === undefined) {
            return undefined;
          }
          return Number(value);
        },
      }}
      {...props}
    />
  );
};

export const TextareaBase = forwardRef((props, ref) => {
  const id = useFormId();
  return (
    <textarea
      ref={ref}
      id={id}
      tw="block w-full shadow-sm text-sm border-gray-300 rounded-md focus:(ring-primary-500 border-primary-500)"
      css={{
        '&[aria-invalid="true"]': tw`border-red-300 text-red-900 placeholder-red-300 focus:(ring-red-500 border-red-500)`,
      }}
      {...props}
    />
  );
});

/** @type {React.FC<{ rules?: import("react-hook-form").RegisterOptions } & React.TextareaHTMLAttributes<HTMLTextAreaElement>>} */
export const Textarea = ({ rules, name, ...props }) => {
  const { register } = useFormContext();
  const error = useFieldError(name);
  return (
    <TextareaBase aria-invalid={error ? "true" : "false"} {...register(name, rules)} {...props} />
  );
};

/** @type {React.FC<React.InputHTMLAttributes<HTMLInputElement>>} */
export const CheckboxBase = forwardRef((props, ref) => {
  const id = useFormId();
  return (
    <input
      ref={ref}
      id={id}
      type="checkbox"
      tw="h-4 w-4 text-primary-600 border-gray-300 rounded focus:(ring-primary-500) disabled:(opacity-50 cursor-not-allowed)"
      css={{
        '&[aria-invalid="true"]': tw`border-red-300 text-red-600 focus:(ring-red-500 border-red-500)`,
      }}
      {...props}
    />
  );
});

/** @type {React.FC<{ rules?: import("react-hook-form").RegisterOptions } & React.InputHTMLAttributes<HTMLInputElement>>} */
export const Checkbox = ({ rules, name, ...props }) => {
  const { register } = useFormContext();
  const error = useFieldError(name);
  return (
    <CheckboxBase aria-invalid={error ? "true" : "false"} {...register(name, rules)} {...props} />
  );
};

/** @type {React.FC<React.InputHTMLAttributes<HTMLInputElement>>} */
export const RadioBase = forwardRef((props, ref) => {
  const id = useFormId();
  return (
    <input
      ref={ref}
      id={id}
      type="radio"
      tw="focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300"
      css={{
        '&[aria-invalid="true"]': tw`border-red-300 text-red-600 focus:(ring-red-500 border-red-500)`,
      }}
      {...props}
    />
  );
});

/** @type {React.FC<{ rules?: import("react-hook-form").RegisterOptions } & React.InputHTMLAttributes<HTMLInputElement>>} */
export const Radio = ({ rules, name, ...props }) => {
  const { register } = useFormContext();
  const error = useFieldError(name);
  return (
    <RadioBase aria-invalid={error ? "true" : "false"} {...register(name, rules)} {...props} />
  );
};

const useSelectStyles = ({ isInvalid }) => {
  const selectStyles = {
    clearIndicator: () => tw`text-gray-300 flex p-2 hover:text-gray-400 transition-colors`,
    container: () => tw`relative mt-1`,
    control: (_, state) => [
      tw`flex h-10 relative bg-white w-full border border-gray-300 rounded-md shadow-sm cursor-default text-sm`,
      state.isFocused && tw`outline-none ring-1 ring-primary-500 border-primary-500`,
      isInvalid && tw`border-red-300 text-red-600`,
      isInvalid && state.isFocused && tw`ring-red-500 border-red-500`,
      state.isDisabled && tw`opacity-50`,
    ],
    dropdownIndicator: () => tw`text-gray-300 flex p-2 hover:text-gray-400 transition-colors`,
    group: () => tw``,
    groupHeading: () => tw`text-gray-400 text-xs font-medium uppercase tracking-wide px-3 py-1.5`,
    indicatorsContainer: () => tw`flex flex-shrink-0 items-center self-stretch`,
    indicatorSeparator: () => tw`w-px my-2 bg-gray-300 self-stretch`,
    input: () => [tw`-my-px`, { "& input": tw`focus:ring-0` }],
    loadingIndicator: () =>
      tw`text-gray-300 flex p-2 hover:text-gray-400 transition-colors font-size[4px]`,
    loadingMessage: () => tw`text-center text-gray-400 py-2 px-3`,
    menu: () =>
      tw`absolute z-10 mt-1 w-full bg-white shadow-lg rounded-md text-base ring-1 ring-black ring-opacity-5 text-sm`,
    menuList: () => tw`max-h-60 py-1 overflow-auto`,
    multiValue: () =>
      tw`inline-flex items-center py-0.5 pl-2 pr-0.5 rounded text-xs font-medium bg-gray-100 text-gray-800`,
    multiValueLabel: () => tw`truncate`,
    multiValueRemove: () =>
      tw`flex-shrink-0 ml-0.5 h-4 w-4 rounded inline-flex items-center justify-center text-gray-400 hover:bg-gray-200 hover:text-gray-500 focus:outline-none focus:bg-gray-500 focus:text-white`,
    noOptionsMessage: () => tw`text-center text-gray-400 py-2 px-3`,
    option: (_, state) => [
      tw`cursor-default select-none py-2 px-3 font-normal text-gray-900 block truncate`,
      state.isDisabled && tw`opacity-50`,
      state.isSelected && tw`font-semibold`,
      state.isFocused && tw`text-white bg-primary-600`,
    ],
    placeholder: () => tw`absolute top-1/2 transform -translate-y-1/2 text-gray-400`,
    singleValue: () => tw`block truncate text-sm text-gray-900`,
    valueContainer: () => tw`flex flex-wrap items-center flex-1 px-3 py-2 gap-1`,
  };
  return selectStyles;
};

const components = {
  DownChevron: ChevronDownIcon,
  CrossIcon: XIcon,
};

/** @type {React.FC<import("react-select").Props & { isInvalid: boolean }>} */
export const SelectBase = forwardRef(({ isInvalid, ...props }, ref) => {
  const selectStyles = useSelectStyles({ isInvalid });
  const id = useFormId();
  return (
    <ReactSelect ref={ref} inputId={id} components={components} styles={selectStyles} {...props} />
  );
});

/** @type {React.FC<import("react-hook-form/dist/types/controller").UseControllerProps & import("react-select").Props>} */
// FIXME:
export const Select = ({ name, rules, defaultValue, onFocus, ...props }) => {
  const { field } = useController({
    name,
    rules,
    defaultValue,
    onFocus,
  });
  const error = useFieldError(props.name);
  return <SelectBase isInvalid={!!error} {...field} {...props} />;
};

/** @type {React.FC<import("react-select").Props & { isInvalid?: boolean }>} */
export const AsyncSelectBase = forwardRef(({ isInvalid, ...props }, ref) => {
  const selectStyles = useSelectStyles({ isInvalid });
  const id = useFormId();
  return (
    <ReactAsyncSelect
      ref={ref}
      inputId={id}
      components={components}
      styles={selectStyles}
      {...props}
    />
  );
});

/** @type {React.FC<import("react-hook-form").UseControllerOptions & React.ComponentPropsWithoutRef<typeof ReactQuill>>} */
export const WYSIWYG = ({ name, rules, defaultValue, ...props }) => {
  const { control } = useFormContext();
  const { field } = useController({
    name,
    control,
    rules,
    defaultValue
  });

  const error = useFieldError(name);

  return (
    <ReactQuill
      theme="snow"
      css={[
        tw`block w-full shadow-sm rounded-md`,
        {
          "& .ql-toolbar.ql-snow": [
            tw`rounded-t-md border border-gray-300`,
            error && tw`border-red-300`,
          ],
          "& .ql-container.ql-snow": [
            tw`font-sans rounded-b-md border border-gray-300`,
            error && tw`border-red-300`,
          ],
          "&:focus-within .ql-container.ql-snow": [
            tw`ring-1 ring-primary-500 border-primary-500`,
            error && tw`ring-red-500 border-red-500`,
          ],
          "&:focus-within .ql-toolbar.ql-snow": [
            tw`ring-1 ring-primary-500 border-primary-500`,
            error && tw`ring-red-500 border-red-500`,
          ],
          "& .ql-editor": {
            minHeight: 180,
          },
          "& .ql-snow.ql-toolbar button": {
            "& .ql-stroke": { stroke: theme`colors.gray.500` },
            "&:hover .ql-stroke": { stroke: theme`colors.gray.700` },
            "& .ql-fill": { fill: theme`colors.gray.500` },
            "&:hover .ql-fill": { fill: theme`colors.gray.700` },
          },
          "& .ql-snow.ql-toolbar .ql-picker-label": {
            "&": tw`font-sans text-gray-500 text-sm`,
            "& .ql-stroke": { stroke: theme`colors.gray.500` },
            "&:hover": tw`text-gray-700`,
            "&:hover .ql-stroke": { stroke: theme`colors.gray.700` },
            "&.ql-active": tw`text-gray-700`,
            "&.ql-active .ql-stroke": { stroke: theme`colors.gray.700` },
          },
          "& .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label": tw`border-transparent text-gray-500`,
          "& .ql-snow.ql-toolbar .ql-picker-item": {
            "&": tw`text-gray-500`,
            "&:hover": tw`text-gray-700`,
            "&.ql-selected": tw`text-gray-700`,
          },
        },
      ]}
      isInvalid={error}
      {...field}
      {...props}
    />
  );
};
