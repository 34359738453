/** @jsxImportSource @emotion/react */
import { useTranslation } from "react-i18next";
import { useJobAdCandidatesByStatusQuery, useJobAdCandidatesCountersByIdQuery } from "../job-ads/JobAdAPI";
import "twin.macro";
import { Dashboard } from "./Dashboard";
import { ContactBlock, ContactBlockSchool } from "./ContactBlock";
import { BestCandidates } from "./BestCandidates";
import { AnnouncementsTable } from "./AnnouncementsTable";
import { useBestCandidates } from "../candidates/CandidateAPI";
import { DashboardSchool } from "./DashboardSchool";
import { AnnouncementsTableSchool } from "./AnnouncementsTableSchool";
import { useSearchParams } from "../shared/useSearchParams";
import { useJobAdSchoolQuery } from "../school/SchoolAPI";

export const JobAdSelectedCandidateCountHome = ({ jobAdId }) => {
  const countQueries = useJobAdCandidatesByStatusQuery(jobAdId, { size: 1, status: "SELECTED" });
  return (
    <span tw="inline-flex items-center px-4 py-0.5 rounded-full text-xs font-medium flex-shrink-0 whitespace-nowrap bg-green-300 text-white">
      {countQueries?.data?.totalElements}
    </span>
  );
};

export const JobAdExtensionCandidateCountHome = ({ jobAdId }) => {
  const { data: jobAdExtension, status: jobAdExtensionStatus } = useJobAdCandidatesCountersByIdQuery(jobAdId);
  return (
    <>
      <td tw="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        <span tw="inline-flex items-center px-4 py-0.5 rounded-full text-xs font-medium flex-shrink-0 whitespace-nowrap bg-blue-300 text-white">
          {jobAdExtension?.totalCandidates}
        </span>
      </td>
      <td tw="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        <span tw="inline-flex items-center px-4 py-0.5 rounded-full text-xs font-medium flex-shrink-0 whitespace-nowrap bg-green-300 text-white">
          {jobAdExtension?.selectedCandidates}
        </span>
      </td>
    </>
  );
};

export const Home = () => {
  const { t } = useTranslation();
  const { status: bestCandidatesStatus, data: bestCandidates } = useBestCandidates();
  const organizationType = localStorage.getItem("organizationType");

  // Get jobAds with paging for SCHOOLS (AnnouncementsTableSchool)
  const { page = 1, size = 10 } = useSearchParams();
  const { status: jobAdsCreatedByUserStatus, data: jobAdsCreatedByUser } = useJobAdSchoolQuery({
    page: page - 1,
    size,
    status: ["PUBLISHED", "PUBLICATION_REQUEST"],
  });

  return (
    <main tw="flex-1 max-h-screen overflow-y-auto pl-4 pr-4 sm:pl-12 sm:pr-12">
      {/* First part */}
      <h1 tw="text-2xl font-extrabold text-gray-900 pt-5 sm:pt-12">{t("home.dashboard")}</h1>
      <div tw="flex flex-wrap justify-center gap-8 mt-4">
        {/* Left block */}
        {organizationType !== "SCHOOL" ? <Dashboard /> : <DashboardSchool />}
        {/* Right block */}
        {organizationType !== "SCHOOL" ? <ContactBlock /> : <ContactBlockSchool />}
      </div>

      {/* Second part */}
      {organizationType !== "SCHOOL" && (
        <BestCandidates
          bestCandidates={bestCandidates}
          bestCandidatesStatus={bestCandidatesStatus}
        />
      )}

      {/* Third part, table */}
      {organizationType !== "SCHOOL" ? (
        <AnnouncementsTable />
      ) : (
        <AnnouncementsTableSchool
          jobAdsCreatedByUserStatus={jobAdsCreatedByUserStatus}
          jobAdsCreatedByUser={jobAdsCreatedByUser}
        />
      )}
    </main>
  );
};
