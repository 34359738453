import { useTranslation } from "react-i18next";
import { getCandidateExperienceYears } from "./CandidateAPI";

export const CandidateExperienceYears = ({ candidateDetails }) => {
  const { t } = useTranslation();
  const candidateExperienceYears = getCandidateExperienceYears(candidateDetails);
  return candidateExperienceYears > 0
    ? t("candidates.experience-year", { count: candidateExperienceYears })
    : null;
};
