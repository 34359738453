import ky from "ky";
import { useMemo } from "react";
import { useAuth } from "../auth/AuthContext";

class APIError extends Error {
  constructor(payload) {
    super(payload.message || "unknownError");
    this.name = "APIError";
    this.payload = payload;
  }
}

/** @typedef {{ sorted: boolean, unsorted: boolean, empty: boolean }} Sort */
/** @typedef {{ sort: Sort, pageNumber: number, pageSize: number, offset: number, paged: boolean, unpaged: boolean }} Pageable */
/**
 * @typedef {{
 * content: T[],
 * pageable: Pageable,
 * totalElements: number,
 * totalPages: number,
 * last: boolean,
 * sort: Sort,
 * numberOfElements: number,
 * first: boolean,
 * size: number,
 * number: number,
 * empty: boolean
 * }} PageableResult
 * @template T
 */

/** @typedef {{ page: number, size: number }} SearchParams */

export const useAPI = (prefixUrl) => {
  const auth = useAuth();
  return useMemo(() => {
    return ky.extend({
      timeout: false,
      prefixUrl,
      hooks: {
        beforeRequest: [
          async (request) => {
            if (auth && auth.accessToken) {
              request.headers.set("Authorization", `Bearer ${auth.accessToken}`);
            }
          },
        ],
        afterResponse: [
          async (_request, _options, response) => {
            if (response.status === 401 && auth && auth.accessToken) { // condition to handle incorrect or invalid accessToken
              auth.logout();
            }
            if (response.status >= 400 && response.status < 600) {
              let payload = {};
              try {
                payload = await response.json();
              } catch (err) {
                console.log("Failed to parse error payload", err);
              }
              throw new APIError(payload);
            }
          },
        ],
      },
    });
  }, [auth, prefixUrl]);
};

export const useCandidateAPI = () => {
  return useAPI(process.env.REACT_APP_CANDIDATE_API_URL);
};

export const useMediaAPI = () => {
  return useAPI(process.env.REACT_APP_MEDIA_API_URL);
};

export const useSkillAPI = () => {
  return useAPI(process.env.REACT_APP_SKILL_API_URL);
};
