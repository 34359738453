/** @jsxImportSource @emotion/react */
import "twin.macro";
import { Container } from "./Container";

export const Page = (props) => {
  return <main tw="flex-1 relative z-0 overflow-y-auto xl:order-last" {...props} />;
};

export const PageHeader = ({ title, actions }) => {
  return (
    <Container as="header">
      <div tw="md:flex md:items-center md:justify-between">
        <div tw="flex-1 min-w-0">{title}</div>
        {actions && <div tw="mt-4 flex-shrink-0 flex md:mt-0 md:ml-4 space-x-3">{actions}</div>}
      </div>
    </Container>
  );
};

export const PageTitle = ({ as: Component = "h1", ...props }) => {
  return <Component tw="text-3xl leading-9 font-extrabold text-gray-900" {...props} />;
};

export const PageContent = (props) => {
  return <Container tw="mt-5" {...props} />;
};

export const PageSkeleton = () => {
  return (
    <Page>
      <PageHeader
        title={
          <PageTitle as="div" tw="h-8 md:h-7 my-1 bg-gray-200 w-1/3 rounded-md animate-pulse" />
        }
      />
    </Page>
  );
};
