import { useMutation, useQuery, useQueryClient } from "react-query";
import { useCandidateAPI } from "../shared/useAPI";

/** @typedef {"FREE" | "PREMIUM"} OrganizationPlan */
/**
 * @typedef {{
 * address: string | null;
 * city: string | null;
 * countryCode: string | null;
 * latitude: string | null;
 * longitude: string | null;
 * placeId: string | null;
 * zipCode: string | null;
 * }} OrganizationLocation
 */
/**
 * @typedef {{
 * billingEmail: string | null;
 * domainUuid: string;
 * legalBusinessName: string | null;
 * location: OrganizationLocation | null;
 * organizationName: string;
 * organizationPlan: OrganizationPlan,
 * organizationUniqueUrlName: string;
 * phone: string | null;
 * taxIdentificationNumber: string| null;
 * uuid: string;
 * vatNumber: string | null;
 * jobAdTokens: number;
 * }} Organization
 */

/** @type {() => import("react-query").UseQueryResult<Organization, Error>} */
export const useOrganizationQuery = () => {
  const candidateAPI = useCandidateAPI();
  return useQuery({
    queryKey: "api/users/me/organization",
    queryFn: async () => candidateAPI.get("api/users/me/organization").json(),
  });
};

export const useOrganizationTokenCount = () => {
  const { data } = useOrganizationQuery();
  if (!data) {
    return 0;
  }
  return data.jobAdTokens;
};

/**
 * @typedef {{
 * location: OrganizationLocation;
 * phone: string;
 * } & Pick<Organization, "billingEmail" | "legalBusinessName" | "taxIdentificationNumber" | "vatNumber" | "organizationName">} OrganizationUpdateInput
 */

/** @type {() => import("react-query").UseMutationResult<Organization, Error, OrganizationUpdateInput>} */
export const useUpdateOrganizationMutation = () => {
  const candidateAPI = useCandidateAPI();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (json) =>
      candidateAPI.put("api/users/me/organization/details", { json }).json(),
    onSuccess: () => {
      queryClient.removeQueries("api/users/me/organization");
    },
  });
};

/** @type {() => import("react-query").UseQueryResult<Organization, Error>} */
export const useLogoOrganizationQuery = () => {
  const candidateAPI = useCandidateAPI();
  return useQuery({
    queryKey: "api/organization/logo.jpeg",
    queryFn: async () => candidateAPI.get("api/organization/logo.jpeg").blob(),
  });
};

/** @type {() => import("react-query").UseMutationResult<Organization, Error, OrganizationUpdateInput>} */
export const useUpdateLogoOrganizationMutation = () => {
  const candidateAPI = useCandidateAPI();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (images) => {
      const formData = new FormData();
      formData.append("image", images[0]);
      candidateAPI.put("api/organization/logo", { body: formData });
    },
    onSuccess: () => {
      queryClient.removeQueries("api/organization/logo.jpeg");
    },
  });
};
