/** @jsxImportSource @emotion/react */
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "twin.macro";
import { useCompaniesLinkedQuery, useDashboardSchool } from "../school/SchoolAPI";
import { useOrganizationQuery } from "../settings/OrganizationAPI";
import { CardDashboard } from "./Dashboard";

export const DashboardSchool = () => {
  const { t } = useTranslation();

  // Get organization PREMIUM or not
  const { status, data } = useOrganizationQuery();
  const { status: statusCompanies, data: dataCompanies } = useCompaniesLinkedQuery();
  //Set the organization type in the local storage
  status === "success" && localStorage.setItem("organizationType", data.organizationType);

  const { status: dashboardSchoolStatus, data: dashboardSchoolData } = useDashboardSchool();

  return (
    <div tw="flex flex-grow flex-col bg-white rounded-md p-4">
      <div tw="flex flex-col md:flex-row items-center justify-center rounded-xl gap-20 md:gap-12 mt-16">
        <CardDashboard
          tittle={t("home.active-job-ads")}
          data={dashboardSchoolStatus === "success" && dashboardSchoolData.publishedAds}
          status={dashboardSchoolStatus}
          tw="bg-green-200"
          logo={
            <figure tw="absolute w-44 h-44 -top-20">
              <img
                src="/homeIcons/jobook_publication_offre_emploi_competences.svg"
                alt=""
                data-ll-status="loaded"
              />
              <noscript>
                <img src="/homeIcons/jobook_publication_offre_emploi_competences.svg" alt="" />
              </noscript>
            </figure>
          }
          link={
            <Link
              to="/job-ads?adStatus=PUBLISHED"
              tw="flex items-center justify-center text-sm font-normal text-white bg-primary-600 border border-transparent rounded-md w-3/6 py-1 hover:bg-primary-700 shadow-md hover:shadow-lg"
            >
              {t("home.see")}
            </Link>
          }
        />

        <CardDashboard
          tittle={t("home.students")}
          data={dashboardSchoolStatus === "success" && dashboardSchoolData.activeCandidates}
          status={dashboardSchoolStatus}
          tw="bg-indigo-300"
          logo={
            <figure tw="absolute w-36 h-36 -top-16">
              <img
                src="/homeIcons/jobook_recrutement_competences_personnalite_intelligence_artificielle-1.svg"
                className="attachment-medium size-medium lazyloaded"
                alt="Communication entre recruteur et candidats"
                data-ll-status="loaded"
              />
              <noscript>
                <img
                  src="/homeIcons/jobook_recrutement_competences_personnalite_intelligence_artificielle-1.svg"
                  className="attachment-medium size-medium"
                  alt="Communication entre recruteur et candidats"
                />
              </noscript>
            </figure>
          }
          link={
            <Link
              to="/students"
              tw="flex items-center justify-center text-sm font-normal text-white bg-primary-600 border border-transparent rounded-md w-3/6 py-1 hover:bg-primary-700 shadow-md hover:shadow-lg"
            >
              {t("home.see")}
            </Link>
          }
        />

        <CardDashboard
          tittle={t("home.companies")}
          data={statusCompanies === "success" && dataCompanies.totalElements}
          status={statusCompanies}
          tw="bg-red-300"
          logo={
            <figure tw="absolute w-36 h-36 -top-16">
              <img
                src="/homeIcons/echange_recruteur_candidat.svg"
                className="attachment-medium size-medium lazyloaded"
                alt="Communication entre recruteur et candidats"
                data-ll-status="loaded"
              />
              <noscript>
                <img
                  src="/homeIcons/echange_recruteur_candidat.svg"
                  className="attachment-medium size-medium"
                  alt="Communication entre recruteur et candidats"
                />
              </noscript>
            </figure>
          }
          link={
            <Link
              to="/companies"
              tw="flex items-center justify-center text-sm font-normal text-white bg-primary-600 border border-transparent rounded-md w-3/6 py-1 hover:bg-primary-700 shadow-md hover:shadow-lg"
            >
              {t("home.see")}
            </Link>
          }
        />
      </div>
    </div>
  );
};
