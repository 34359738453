export const Logo = (props) => {
  return (
    <svg
      version="1.1"
      id="Calque_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="376.067px"
      height="100.063px"
      viewBox="0 0 376.067 100.063"
      enableBackground="new 0 0 376.067 100.063"
      fill="none"
      {...props}
    >
      <path
        fill="#2879FE"
        d="M47.913,18.025V62.63c0,11.112-9.009,20.121-20.121,20.121S7.671,73.742,7.671,62.63h11.191
	c0,4.933,3.998,8.931,8.929,8.931c4.933,0,8.931-3.998,8.931-8.931V29.214h-8.931V18.025H47.913z"
      />
      <path
        fill="#FF2764"
        d="M104.375,62.307c0,8.236-4.961,15.662-12.57,18.813c-7.608,3.15-16.367,1.409-22.188-4.414
	c-5.824-5.822-7.565-14.581-4.414-22.188s10.575-12.568,18.812-12.568C95.251,41.961,104.359,51.07,104.375,62.307z M84.013,71.479
	c5.063-0.006,9.166-4.107,9.17-9.172c0-3.709-2.234-7.054-5.662-8.473c-3.426-1.42-7.371-0.635-9.994,1.987
	c-2.625,2.624-3.408,6.567-1.988,9.995C76.959,69.244,80.304,71.478,84.013,71.479L84.013,71.479z"
      />
      <path
        fill="#F6C159"
        d="M160.814,62.307c0,8.236-4.959,15.662-12.566,18.813c-7.609,3.15-16.367,1.409-22.191-4.414
	c-5.822-5.822-7.564-14.581-4.412-22.188c3.15-7.607,10.574-12.568,18.811-12.568C151.693,41.961,160.802,51.07,160.814,62.307z
	 M140.455,71.479c5.063-0.006,9.168-4.107,9.17-9.172c0-3.709-2.234-7.054-5.66-8.473c-3.428-1.42-7.373-0.635-9.996,1.987
	c-2.623,2.624-3.406,6.567-1.986,9.995C133.4,69.244,136.746,71.478,140.455,71.479L140.455,71.479z"
      />
      <polygon
        fill="#FF2764"
        points="36.722,29.22 47.913,29.22 47.913,18.025 27.792,18.025 27.792,29.214 36.722,29.214 "
      />
      <polygon
        fill="#41C982"
        points="131.259,41.443 131.275,41.443 131.275,18.022 120.085,18.022 120.085,82.752 131.259,82.752 "
      />
      <polygon
        fill="#2443A5"
        points="41.711,29.22 47.913,29.22 47.913,18.025 36.763,18.025 36.763,29.214 41.711,29.214 "
      />
      <g>
        <path
          fill="#499F40"
          d="M121.644,54.517c-3.152,7.608-1.412,16.366,4.412,22.189c1.553,1.554,3.318,2.813,5.211,3.771V44.141
		C127.009,46.295,123.546,49.922,121.644,54.517z"
        />
      </g>
      <g display="none">
        <path
          display="inline"
          fill="#E2E2E2"
          d="M510.782,32.88c-75.17,0-136.324-61.154-136.324-136.323s61.154-136.324,136.324-136.324
		c75.168,0,136.322,61.155,136.322,136.324S585.95,32.88,510.782,32.88z M510.782-228.318c-68.857,0-124.875,56.021-124.875,124.875
		c0,68.856,56.018,124.875,124.875,124.875c68.854,0,124.875-56.019,124.875-124.875
		C635.657-172.299,579.637-228.318,510.782-228.318z"
        />
      </g>
      <g id="Rectangle_arrondi_1_2_">
        <g>
          <path
            fill="#FF2764"
            d="M107.335,28.578c-0.813-0.872-2.18-0.919-3.051-0.107l-6.948,6.479c-0.872,0.813-0.92,2.179-0.106,3.05
			c0.813,0.873,2.178,0.921,3.051,0.107l6.947-6.477C108.101,30.816,108.148,29.449,107.335,28.578z"
          />
        </g>
      </g>
      <g id="Rectangle_arrondi_1_copie_2_2_">
        <g>
          <path
            fill="#FF2764"
            d="M72.91,34.948l-6.947-6.479c-0.871-0.812-2.238-0.765-3.052,0.107s-0.765,2.238,0.106,3.051l6.949,6.476
			c0.871,0.814,2.237,0.767,3.051-0.106C73.83,37.126,73.782,35.76,72.91,34.948z"
          />
        </g>
      </g>
      <g id="Rectangle_arrondi_1_copie_2_">
        <g>
          <path
            fill="#FF2764"
            d="M84.392,17.313c-1.191-0.041-2.19,0.891-2.232,2.083l-0.333,9.492c-0.042,1.192,0.89,2.19,2.082,2.233
			c1.19,0.042,2.19-0.892,2.233-2.082l0.332-9.494C86.517,18.354,85.584,17.355,84.392,17.313z"
          />
        </g>
      </g>
      <path
        fill="#F6C159"
        d="M221.48,26.012V13.243h12.095v12.769H221.48z M221.48,83.008V39.054h12.095v43.954H221.48z"
      />
      <path
        fill="#41C982"
        d="M280.209,43.762l-8.551,8.555c-1.225-1.228-3.069-1.595-4.671-0.931c-1.603,0.663-2.647,2.227-2.646,3.96
	v27.663h-12.095V38.965h12.095v0.564c1.397-0.376,2.839-0.565,4.286-0.564C272.974,38.953,277.144,40.681,280.209,43.762z"
      />
      <path
        fill="#FF2764"
        d="M319.578,59.038c3.987,3.986,5.199,9.973,3.077,15.196s-7.165,8.667-12.803,8.742h-17.346V70.882h17.156
	c1.065,0,1.929-0.863,1.929-1.928c0-1.065-0.863-1.929-1.929-1.929h-7.219c-7.75,0-14.031-6.282-14.031-14.03
	c0-7.75,6.281-14.031,14.031-14.031h17.157v12.095h-17.157c-1.069,0-1.937,0.866-1.937,1.936s0.867,1.936,1.937,1.936h7.219
	C313.383,54.92,316.954,56.4,319.578,59.038L319.578,59.038z"
      />
      <rect x="221.48" y="13.243" fill="#2879FE" width="12.095" height="12.769" />
      <rect x="338.566" y="13.053" fill="#2879FE" width="12.095" height="43.896" />
      <g>
        <path
          fill="#2879FE"
          d="M350.661,51.06V38.965v-3.6V13.053h-12.095v25.912h-0.008V51.06h0.008v20.08
		c0.007,6.535,5.302,11.83,11.836,11.837h6.048V70.882h-5.789V59.698V51.06z"
        />
      </g>
      <rect x="335.057" y="19.965" fill="#41C982" width="26.021" height="12.095" />
      <g>
        <path
          fill="#2879FE"
          d="M268.627,38.965c-1.447-0.001-2.889,0.188-4.286,0.564v15.816c-0.001-1.733,1.044-3.297,2.646-3.96
		c1.602-0.664,3.446-0.297,4.671,0.931l8.551-8.555C277.144,40.681,272.974,38.953,268.627,38.965z"
        />
      </g>
      <g>
        <path
          fill="#FF2764"
          d="M190.851,38.966v-6.31v-7.479h5.788V13.082h-6.048c-6.534,0.008-11.828,5.303-11.835,11.836v7.738h0.02
		v29.652h-0.02v20.697h12.095V62.309V51.061V38.966z"
        />
      </g>
      <rect x="175.247" y="39.815" fill="#41C982" width="30.153" height="12.095" />
      <rect x="195.639" y="13.082" fill="#FF2764" width="9.761" height="12.095" />
      <rect x="356.051" y="70.882" fill="#2879FE" width="5.027" height="12.095" />
      <rect x="284.875" y="70.914" fill="#FF2764" width="7.93" height="12.094" />
    </svg>
  );
};
