export const qualityLabel = {
  // ChangeHabilityTitle: "qualities.ability-to-change",
  // StressSensibilityTitle: "qualities.stress-sensibility",
  // ServiceOrientedTitle: "qualities.service-oriented",
  // DisciplineTitle: "qualities.discipline",
  // CombativenessTitle: "qualities.combativeness",
  // DrivingCapacityTitle: "qualities.driving-capacity",
  // ExpliciteTitle: "qualities.unambiguous",
  // GuidanceTitle: "qualities.guidance",
  // ListenningTitle: "qualities.listening",
  // TeamTitle: "qualities.team-work",
  // ActionCapacityTitle: "qualities.action-capacity",
  // OrganisationTitle: "qualities.organisation",
  // PrioritiesOrientedTitle: "qualities.priorities-oriented",
  // SpecialisationTitle: "qualities.specialisation",
  // TaskOrientedTitle: "qualities.task-oriented",
  AudacityTitle: "qualitiesV2.audacity",
  PerseveranceTitle: "qualitiesV2.perseverance",
  IntuitionTitle: "qualitiesV2.intuition",
  SelfOrganizationTitle: "qualitiesV2.self-organization",
  DynamismTitle: "qualitiesV2.dynamism",
  CreativityTitle: "qualitiesV2.creativity",
  AdaptationTitle: "qualitiesV2.adaptation",
  TeamSpiritTitle: "qualitiesV2.team-spirit",
  OpenListeningTitle: "qualitiesV2.open-listening",
  UnderstandingOthersTitle: "qualitiesV2.understanding-others",
  ContactSkillsTitle: "qualitiesV2.contact-skills",
  ServiceSenseTitle: "qualitiesV2.service-sense",
  FramingTitle: "qualitiesV2.framing",
  DrivingCapacityTitle: "qualitiesV2.driving-capacity",
  CognitiveFlexibilityTitle: "qualitiesV2.cognitive-flexibility",
  OrganizationTitle: "qualitiesV2.organization",
  ProblemSolvingTitle: "qualitiesV2.problem-solving",
  DecisionMakingTitle: "qualitiesV2.decision-making",
  ActionCapacityTitle: "qualitiesV2.action-capacity",
  SenseOfPrioritiesTitle: "qualitiesV2.sense-of-priorities",
  InitiativeCapacityTitle: "qualitiesV2.initiative-capacity",
};

export const qualityCategoryLabel = {
  // selfmanagement: "qualities.personal",
  // relationships: "qualities.relational",
  // activities: "qualities.operational",
  individual: "qualitiesV2.individual",
  relational: "qualitiesV2.relational",
  operational: "qualitiesV2.operational"
};
