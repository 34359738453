import { useMutation, useQuery } from "react-query";
import { useCandidateAPI } from "../shared/useAPI";

export const useJobAdSchoolQuery = (searchParams) => {
  const api = useCandidateAPI();
  return useQuery({
    queryKey: [`api/school/domain/${process.env.REACT_APP_APPLICATION_DOMAIN}/my-jobads`, searchParams],
    queryFn: async () => api.get(`api/school/domain/${process.env.REACT_APP_APPLICATION_DOMAIN}/my-jobads`, { searchParams }).json(),
  });
};

export const useDashboardSchool = () => {
  const api = useCandidateAPI();
  return useQuery({
    queryKey: `api/school/dashboard`,
    queryFn: async () => api.get(`api/school/dashboard`).json(),
  });
};

//Set an url in order to link student to a school
export const getStudentEnrollURL = ({ uuid, url, schoolName }) => {
  return `${url}/partnership?studentPool=${uuid}&schoolName=${schoolName}`;
};

//Set an url in order to link company to a school
export const getCompanyEnrollURL = ({ uuid, url, schoolName }) => {
  return `${url}/partnership?school=${uuid}&schoolName=${schoolName}`;
};

export const useStudentPoolUuid = () => {
  const api = useCandidateAPI();
  return useQuery({
    queryKey: `api/school/studentpool`,
    queryFn: async () => api.get(`api/school/studentpool`).json(),
  });
};

//Get all the schools
export const useSchoolsQuery = () => {
  const api = useCandidateAPI();
  return useQuery({
    queryKey: [`api/users/me/organization/domain/${process.env.REACT_APP_APPLICATION_DOMAIN}/schools`],
    queryFn: async () => api.get(`api/users/me/organization/domain/${process.env.REACT_APP_APPLICATION_DOMAIN}/schools`).json(),
  });
};

//Get all the job by school
export const useJobSchoolsQuery = (schoolUuids) => {
  const api = useCandidateAPI();
  return useQuery({
    queryKey: [`api/school/jobindex`],
    queryFn: async () => api.post(`api/school/jobindex`, { json: schoolUuids }).json(),
  });
};

//Link a company to a school. The body is the school id of the school
export const useCompanyLinkedToSchoolMutation = () => {
  const api = useCandidateAPI();
  return useMutation({
    mutationFn: async (schoolId) => {
      api.put("api/users/me/organization/schools", { json: schoolId });
    },
  });
};

//Get all the students of a school
export const useActiveCandidatesOfSchoolsQuery = (searchParams) => {
  const api = useCandidateAPI();
  return useQuery({
    queryKey: [`api/school/domain/${process.env.REACT_APP_APPLICATION_DOMAIN}/my-active-candidates`, searchParams],
    queryFn: async () => api.get(`api/school/domain/${process.env.REACT_APP_APPLICATION_DOMAIN}/my-active-candidates`, { searchParams }).json(),
    keepPreviousData: true,
  });
};

//Get all the companies linked with the school
export const useCompaniesLinkedQuery = (searchParams) => {
  const api = useCandidateAPI();
  return useQuery({
    queryKey: [`api/school/my-companies`, searchParams],
    queryFn: async () => api.get(`api/school/my-companies`, { searchParams }).json(),
  });
};
