/** @jsxImportSource @emotion/react */
import qs from "qs";
import { Link, useLocation } from "react-router-dom";
import "twin.macro";
import { ButtonBaseWithoutFocus } from "./Button";
import { useSearchParams } from "./useSearchParams";

export const PaginationWithNumber = ({
  page: currentPage,
  pageSize,
  totalCount,
  pageSearchParam = "page",
  component,
}) => {
  const pageCount = Math.ceil(totalCount / pageSize);
  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage >= pageCount;
  const searchParams = useSearchParams();
  const location = useLocation();
  const getPageUrl = (page) => {
    return `${location.pathname}${qs.stringify(
      {
        ...searchParams,
        [component === "candidates" ? "pageCandidate" : pageSearchParam]: Math.min(
          pageCount,
          Math.max(1, page)
        ),
      },
      { addQueryPrefix: true }
    )}`;
  };

  return (
    <footer tw="px-4 py-3 flex items-center justify-between border-t border-gray-200">
      <div tw="flex-1 flex items-center space-x-3">
        <ButtonBaseWithoutFocus as={Link} to={getPageUrl(currentPage - 1)} disabled={isFirstPage}>
          &lt;
        </ButtonBaseWithoutFocus>

        {currentPage === 2 || currentPage === 1 ? null : (
          <ButtonBaseWithoutFocus as={Link} to={getPageUrl(currentPage - 2)} disabled={isFirstPage}>
            {currentPage - 2}
          </ButtonBaseWithoutFocus>
        )}

        {1 === currentPage ? null : (
          <ButtonBaseWithoutFocus as={Link} to={getPageUrl(currentPage - 1)} disabled={isFirstPage}>
            {currentPage - 1}
          </ButtonBaseWithoutFocus>
        )}

        <ButtonBaseWithoutFocus as={Link} to={getPageUrl(currentPage)} disabled>
          {currentPage}
        </ButtonBaseWithoutFocus>

        {pageCount === currentPage ? null : (
          <ButtonBaseWithoutFocus as={Link} to={getPageUrl(currentPage + 1)} disabled={isLastPage}>
            {currentPage + 1}
          </ButtonBaseWithoutFocus>
        )}

        {pageCount === currentPage || pageCount - 1 === currentPage ? null : (
          <ButtonBaseWithoutFocus as={Link} to={getPageUrl(currentPage + 2)} disabled={isLastPage}>
            {currentPage + 2}
          </ButtonBaseWithoutFocus>
        )}

        <ButtonBaseWithoutFocus as={Link} to={getPageUrl(currentPage + 1)} disabled={isLastPage}>
          &gt;
        </ButtonBaseWithoutFocus>
      </div>
    </footer>
  );
};
