// @ts-check
import { useQuery, useQueryClient } from "react-query";
import { useSkillAPI } from "../shared/useAPI";
import { useLanguage } from "../shared/useLanguage";

/**
 * @typedef JobSkills
 * @property {import("../job-ads/JobAdAPI").JobAdSkill[]} accreditations
 * @property {import("../job-ads/JobAdAPI").JobAdSkill[]} activities
 * @property {import("../job-ads/JobAdAPI").JobAdSkill[]} skills
 */

/** @type {(jobCode: string, searchParams: { lang: import("../shared/useLanguage").Language }) => import("react-query").UseQueryResult<JobSkills>} */
export const useSkillsByJobCodeQuery = (jobCode, searchParams) => {
  const api = useSkillAPI();
  return useQuery(
    [`api/skill/candidate/job/byCode/${jobCode}/skills`, searchParams],
    async () => {
      /** @type {JobSkills} */
      const { activities, skills } = await api
        .get(`api/skill/candidate/job/byCode/${jobCode}/skills`, {
          searchParams,
        })
        .json();
      // When requesting "api/skill/candidate/job/byCode/${jobCode}/skills",
      // the accreditations are always left empty, we must filter "activities" with the "accreditation" property set to true to resolve them.
      return {
        activities: (activities || []).filter((activity) => !activity.accreditation),
        accreditations: (activities || []).filter((activity) => activity.accreditation),
        skills: skills || [],
      };
    },
    {
      enabled: !!jobCode,
    }
  );
};

const skillNameMapping = {
  FR: "libelleFr",
  EN: "libelleEn",
  DE: "libelleDe",
  NL: "libelleNl",
  ES: "libelleEs",
  IT: "libelleIt",
  PT: "libellePt",
};

/** @type {() => (skillId: string) => Promise<import("../job-ads/JobAdAPI").JobAdSkill>} */
const useFetchSkillById = () => {
  const queryClient = useQueryClient();
  const skillAPI = useSkillAPI();

  return (skillId) => {
    return queryClient.fetchQuery(`api/skill/candidate/skill/${skillId}`, async () => {
      return skillAPI.get(`api/skill/candidate/skill/${skillId}`).json();
    });
  };
};

/** @type {() => (activityId: string) => Promise<import("../job-ads/JobAdAPI").JobAdSkill>} */
const useFetchActivityById = () => {
  const queryClient = useQueryClient();
  const skillAPI = useSkillAPI();

  return (activityId) => {
    return queryClient.fetchQuery(`api/skill/candidate/activity/${activityId}`, async () => {
      return skillAPI.get(`api/skill/candidate/activity/${activityId}`).json();
    });
  };
};

/** @type {(skill: import("../job-ads/JobAdAPI").JobAdSkill, language: import("../shared/useLanguage").Language) => string} */
const resolveSkillName = (skill, language) => {
  const property = skillNameMapping[language];
  return skill[property] || skill.libelleEn || skill.name;
};

const useFetchTranslatedSkillNameById = () => {
  const language = useLanguage();
  const fetchSkillById = useFetchSkillById();
  return async (skillId) => {
    try {
      return resolveSkillName(await fetchSkillById(skillId), language);
    } catch (err) {
      return null;
    }
  };
};

const useFetchTranslatedActivityNameById = () => {
  const language = useLanguage();
  const fetchActivityById = useFetchActivityById();
  return async (activityId) => {
    try {
      return resolveSkillName(await fetchActivityById(activityId), language);
    } catch (err) {
      return null;
    }
  };
};

/** @type {() => (skills: import("../candidates/CandidateAPI").Skill[]) => Promise<import("../candidates/CandidateAPI").Skill[]>} */
export const useFetchTranslatedSkills = () => {
  const fetchTranslatedSkillNameById = useFetchTranslatedSkillNameById();
  return (skills) => {
    return Promise.all(
      skills.map(async (skill) => ({
        ...skill,
        name: skill.id
          ? (await fetchTranslatedSkillNameById(skill.id)) ||
            // Fallback to skill name if the API fails to respond
            skill.name
          : skill.name,
      }))
    );
  };
};

/** @type {() => (skills: import("../candidates/CandidateAPI").Skill[]) => Promise<import("../candidates/CandidateAPI").Skill[]>} */
export const useFetchTranslatedActivities = () => {
  const fetchTranslatedActivityNameById = useFetchTranslatedActivityNameById();
  return (activities) => {
    return Promise.all(
      activities.map(async (activity) => ({
        ...activity,
        name: activity.id
          ? (await fetchTranslatedActivityNameById(activity.id)) ||
            // Fallback to activity name if the API fails to respond
            activity.name
          : activity.name,
      }))
    );
  };
};
