/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import tw from "twin.macro";
import { Badge } from "./Badge";

export const Skeleton = styled("span")(
  tw`block h-4 my-0.5 bg-gray-100 w-32 rounded-md animate-pulse`
);

export const AvatarSkeleton = styled("span")(
  tw`block h-12 w-12 rounded-full bg-gray-100 flex-shrink-0 animate-pulse`
);

export const BadgeSkeleton = (props) => {
  return (
    <Badge color="gray" {...props}>
      <Skeleton tw="w-8 bg-gray-200 h-3" />
    </Badge>
  );
};
