/** @jsxImportSource @emotion/react */
import { resolveAddressFromGeocoderResult, useGeocodeQuery } from "../google/GoogleAPI";
import "twin.macro";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";

/** @type {React.FC<{ mobility: import("./CandidateAPI").Mobility }>} */
export const CandidateLocation = ({ candidateDetails, jobAdLocation }) => {

  const getDistanceFromLatLonInKm = (lat1, lon1, lat2, lon2) => {
    var R = 6371; // Radius of the earth in km
    var dLat = deg2rad(lat2 - lat1);  // deg2rad below
    var dLon = deg2rad(lon2 - lon1);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2)
      ;
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km
    return d;
  }

  const deg2rad = deg => deg * (Math.PI / 180);

  const { t } = useTranslation();
  const mobility = candidateDetails.mobility;
  let distance = null;
  let isDistanceMobilityOver = null;
  if (jobAdLocation && mobility?.radiusInKm) {
    distance = getDistanceFromLatLonInKm(jobAdLocation.latitude, jobAdLocation.longitude, mobility.latitude, mobility.longitude)
    isDistanceMobilityOver = distance > mobility.radiusInKm;
  }
  const geocodeRequest = mobility
    ? { location: { lat: mobility.latitude, lng: mobility.longitude } }
    : null;
  const { data } = useGeocodeQuery(geocodeRequest);
  const geocoderResult = data && data.length !== 0 ? data[0] : null;
  const address = geocoderResult ? resolveAddressFromGeocoderResult(geocoderResult) : null;
  const formattedAddress = mobility?.address ? mobility.address : (address ? [address.line, address.city, address.country].filter(Boolean).join(", ") : null);
  return (
    <>
      {formattedAddress &&
        <span>{formattedAddress}</span>
      }
      {
        isDistanceMobilityOver === true && (
          <>
            <br />
            <span tw="text-red-400">{t(`candidates.MobilityDistance`, { radiusInKm: mobility?.radiusInKm })}</span>
          </>
        )
      }
      {
        isDistanceMobilityOver === false && (
          <>
            <br />
            <span tw="text-green-400">{t(`candidates.MobilityDistance`, { radiusInKm: mobility?.radiusInKm })}</span>
          </>
        )
      }
    </>
  );
};