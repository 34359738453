import styled from "@emotion/styled";
import tw from "twin.macro";

export const EmptyState = styled("div")(tw`flex flex-col items-center px-4 sm:px-6 py-16`);

export const EmptyStateIllustration = styled("svg")(
  tw`mt-16 sm:mt-24 md:mt-32 max-w-sm mx-auto flex-shrink-0 mb-16`
);

export const EmptyStateTitle = styled("h1")(
  tw`text-xl font-medium text-gray-900 text-center`
);

export const EmptyStateDescription = styled("p")(
  tw`max-w-lg text-sm text-gray-500 text-center mt-2`
);
