/** @jsxImportSource @emotion/react */
import qs from "qs";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import "twin.macro";
import { Button } from "./Button";
import { useSearchParams } from "./useSearchParams";

export const Pagination = ({
  page: currentPage,
  pageSize,
  totalCount,
  pageSearchParam = "page",
  component,
}) => {
  const { t } = useTranslation();
  const pageCount = Math.ceil(totalCount / pageSize);
  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage >= pageCount;
  const searchParams = useSearchParams();
  const location = useLocation();
  const getPageUrl = (page) => {
    return `${location.pathname}${qs.stringify(
      {
        ...searchParams,
        [component === "candidates" ? "pageCandidate" : pageSearchParam]: Math.min(
          pageCount,
          Math.max(1, page)
        ),
      },
      { addQueryPrefix: true }
    )}`;
  };

  return (
    <footer tw="px-4 py-3 flex items-center justify-between border-t border-gray-200">
      {/* Mobile navigation (Previous / Next buttons) */}
      <div tw="flex-1 flex items-center justify-between space-x-3">
        <Button as={Link} to={getPageUrl(currentPage - 1)} disabled={isFirstPage}>
          {t("shared.previous")}
        </Button>
        <Button as={Link} to={getPageUrl(currentPage + 1)} disabled={isLastPage}>
          {t("shared.next")}
        </Button>
      </div>
    </footer>
  );
};
