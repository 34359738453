import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import requireContext from "require-context.macro";

const localImportFn = requireContext("./locales", true, /\.json$/, "lazy");
const jobCardModuleImportFn = requireContext(
  "@jobook-io/jobook-job-card/dist/locales",
  true,
  /\.json$/,
  "lazy"
);

const loadingFnByNamespace = {
  "@jobook-io/jobook-job-card": (language) => jobCardModuleImportFn(`./${language}.json`),
  translation: (language) => localImportFn(`./${language}.json`),
};
const defaultLoadingFn = (language, ns) => localImportFn(`./${language}.${ns}.json`);

class WebpackBackend {
  static type = "backend";
  async read(language, ns, callback) {
    try {
      const loadingFn = loadingFnByNamespace[ns] || defaultLoadingFn;
      const translation = await loadingFn(language, ns);
      return callback(null, translation);
    } catch (error) {
      return callback(error, null);
    }
  }
}

i18n
  .use(LanguageDetector)
  .use(WebpackBackend)
  .use(initReactI18next)
  .init({
    debug: process.env.NODE_ENV !== "production" && process.env.NODE_ENV !== "test",
    fallbackLng: "en",
    defaultNS: process.env.REACT_APP_I18N_NS,
    fallbackNS: "translation",
    ns: ["translation", process.env.REACT_APP_I18N_NS],
    nsSeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
