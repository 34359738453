export const diplomaLabel = {
  NO_DIPLOMA: "diplomas.no-diploma",
  CAP_BEP: "diplomas.cap-bep",
  BACCALAUREAT: "diplomas.baccalaureat",
  BTS_DUT: "diplomas.bts-dut",
  LICENSE: "diplomas.license",
  MASTER1: "diplomas.master1",
  MASTER2: "diplomas.master2",
  PHD: "diplomas.phd",
};
