// @ts-check
import { CometChat } from "@cometchat-pro/chat";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useAuth } from "../auth/AuthContext";
import { useCandidateAPI } from "../shared/useAPI";

const initializeCometChat = async () => {
  const appID = process.env.REACT_APP_COMETCHAT_APP_ID;
  const region = "eu";
  const appSetting = new CometChat.AppSettingsBuilder()
    .subscribePresenceForAllUsers()
    .setRegion(region)
    .build();
  await CometChat.init(appID, appSetting);
};

let loginPromise = null;
/** @type {() => import("react-query").UseMutationResult<void, unknown, { chatAccessToken: string }>} */
export const useAuthenticateCometChatMutation = () => {
  return useMutation(async ({ chatAccessToken }) => {
    await initializeCometChat();
    if (!chatAccessToken) {
      throw new Error("Could not authenticate to chat");
    }
    if (!loginPromise) {
      loginPromise = CometChat.login(chatAccessToken);
    }
    await loginPromise;
    loginPromise = null;
  });
};

/** @type {() => import("react-query").UseMutationResult<Response, unknown, { chatWithUser: string }>} */
export const useInitiateChatMutation = () => {
  const api = useCandidateAPI();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ chatWithUser }) => api.put("api/chat/initiate", { body: chatWithUser }),
    onSuccess: async () => {
      await queryClient.invalidateQueries("api/users/me");
    },
  });
};

/** @type {() => import("react-query").UseMutationResult<Response, unknown, { userId: string }>} */
export const useMessageSentMutation = () => {
  const api = useCandidateAPI();
  return useMutation({
    mutationFn: async ({ userId }) => api.put(`api/chat/domain/${process.env.REACT_APP_APPLICATION_DOMAIN}/message`, { body: userId }),
  });
};

/** @type {() => string | undefined} */
export const useChatAccessToken = () => {
  const { user } = useAuth();
  if (!user) {
    return undefined;
  }
  return user.chatAccessToken;
};

export const useHasChatEnabled = () => {
  const chatAccessToken = useChatAccessToken();
  return !!chatAccessToken;
};

export const useUnreadMessageCount = () => {
  const { mutateAsync: authenticateCometChat } = useAuthenticateCometChatMutation();
  const chatAccessToken = useChatAccessToken();
  return useQuery({
    queryKey: "unreadMessageCount",
    queryFn: async () => {
      try {
        await authenticateCometChat({ chatAccessToken });
        const unreadMessageCount = await CometChat.getUnreadMessageCountForAllUsers();
        return Object.values(unreadMessageCount).reduce((acc, count) => acc + count, 0);
      } catch (error) {
        return 0;
      }
    },
  });
};