/** @jsxImportSource @emotion/react */
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "twin.macro";
import { PrimaryButton } from "../shared/Button";
import {
  EmptyState,
  EmptyStateDescription,
  EmptyStateIllustration,
  EmptyStateTitle,
} from "../shared/EmptyState";
import { Page, PageContent } from "../shared/Page";
import { NotFoundIllustration } from "./NotFoundIllustration";

const NotFoundRoute = () => {
  const { t } = useTranslation();
  return (
    <>
      <Helmet title={t("not-found.title")} />
      <Page>
        <PageContent>
          <EmptyState>
            <EmptyStateIllustration as={NotFoundIllustration} />
            <EmptyStateTitle>{t("not-found.title")}</EmptyStateTitle>
            <EmptyStateDescription>{t("not-found.description")}</EmptyStateDescription>
            <PrimaryButton as={Link} to="/" tw="mt-8">
              {t("not-found.go-back-to-dashboard")}
            </PrimaryButton>
          </EmptyState>
        </PageContent>
      </Page>
    </>
  );
};

export default NotFoundRoute;
