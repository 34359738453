// @see https://formatjs.io/docs/polyfills/
// Polyfill Intl.getCanonicalLocales (required by Intl.Locale)
import "@formatjs/intl-getcanonicallocales/polyfill";
// Polyfill Intl.Locale (required by Intl.PluralRules)
import "@formatjs/intl-locale/polyfill";
// Polyfill Intl.PluralRules (required by Intl.DateTimeFormat)
import "@formatjs/intl-pluralrules/polyfill";
import "@formatjs/intl-pluralrules/locale-data/en";
import "@formatjs/intl-pluralrules/locale-data/fr";
import "@formatjs/intl-pluralrules/locale-data/es";
import "@formatjs/intl-pluralrules/locale-data/pt";
// Polyfill Intl.DateTimeFormat
import "@formatjs/intl-datetimeformat/polyfill";
import "@formatjs/intl-datetimeformat/locale-data/en";
import "@formatjs/intl-datetimeformat/locale-data/fr";
import "@formatjs/intl-datetimeformat/locale-data/es";
import "@formatjs/intl-datetimeformat/locale-data/pt";
import "@formatjs/intl-datetimeformat/add-golden-tz";
import { useTranslation } from "react-i18next";

export const useFormatDate = (/** @type {Intl.DateTimeFormatOptions} */ options) => {
  const { i18n } = useTranslation();
  const formatter = new Intl.DateTimeFormat(i18n.language, options);
  return (/** @type {Date} */ date) => formatter.format(date);
};
