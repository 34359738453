/** @jsxImportSource @emotion/react */
import tw from "twin.macro";
import { EmptyState, EmptyStateDescription } from "../shared/EmptyState";
import { useTranslation } from "react-i18next";
import { StarRating } from "../shared/StarRating";

/** @type {(highlighted: import("../job-ads/JobAdAPI").JobAdSkill[], skill: import("./CandidateAPI").Skill) => boolean} */
const isSkillHighlighted = (highlighted, skill) => {
  return highlighted.map((jobAdSkill) => jobAdSkill.id).includes(skill.id);
};

/** @type {React.FC<{ skill: import("./CandidateAPI").Skill, highlighted: import("../job-ads/JobAdAPI").JobAdSkill[] }>} */
export const SkillListItem = ({ skill, highlighted }) => {
  const { t } = useTranslation();
  return (
    <div>
      { (skill.graduationDate || (!skill.graduationDate && skill.eval === 0)) && (
        <li
          tw="rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm justify-between space-x-2"
          css={
            highlighted && isSkillHighlighted(highlighted, skill) && tw`bg-green-100 border-green-300`
          }
        >
          <p tw="text-sm font-medium text-gray-900" css={{ "&:first-letter": tw`capitalize` }}>
            {skill.name}
          </p>
          { skill.graduationDate && (
          <p tw="text-sm font-medium text-gray-500" style={{marginLeft: 0}}>{t("candidates.graduation-date")}: {skill.graduationDate.split("-").reverse().join("/")}</p>
          )}
          </li>
      )}
      { !skill.graduationDate && skill.eval > 0 && (
        <li
          tw="rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-start justify-between space-x-2"
          css={
            highlighted && isSkillHighlighted(highlighted, skill) && tw`bg-green-100 border-green-300`
          }
        >
          <p tw="text-sm font-medium text-gray-900 flex-1" css={{ "&:first-letter": tw`capitalize` }}>
            {skill.name}
          </p>
          {skill.eval !== 0 && <StarRating rating={skill.eval} />}
        </li>
      )}
    </div>
  );
};

/** @type {React.FC<{ skills?: import("./CandidateAPI").Skill[], empty: JSX.Element, highlighted: import("../job-ads/JobAdAPI").JobAdSkill[] }>} */
export const SkillList = ({ skills, empty, highlighted }) => {
  const isEmpty = !skills || skills.length === 0;
  return isEmpty ? (
    <EmptyState tw="bg-gray-100 rounded-lg">
      <EmptyStateDescription tw="mt-0">{empty}</EmptyStateDescription>
    </EmptyState>
  ) : (
    <ul tw="grid grid-cols-1 gap-4 sm:grid-cols-2">
      {skills.map((skill) => (
        <SkillListItem key={skill.id} skill={skill} highlighted={highlighted} />
      ))}
    </ul>
  );
};
