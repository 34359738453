/** @jsxImportSource @emotion/react */
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "twin.macro";
import { JobAdMatchingScore } from "../job-ads/JobAdMatchingScore";
import { JobAdMatchingCustomMatchingGlobalCircleForList } from "../job-ads/JobAdCustomMatchingScores"
import { Skeleton } from "../shared/Skeleton";
import { useFormatDate } from "../shared/useFormatDate";

export const BestCandidates = ({ bestCandidatesStatus, bestCandidates }) => {
  const { t } = useTranslation();

  const formatDate = useFormatDate({ dateStyle: "medium" });

  //Number of items mapped during the loading
  const loadingBestCandidates = 10;

  return (
    <>
      {bestCandidatesStatus === "loading" && (
        <>
          <h1 tw="text-2xl font-extrabold text-gray-900 mt-20">{t("home.best-profiles")}</h1>
          <div tw="flex flex-wrap justify-center sm:justify-start gap-8 mt-4">
            {[...Array(loadingBestCandidates)].map((e, index) => (
              <div
                tw="flex flex-col w-full sm:w-60 truncate shadow-md hover:shadow-lg rounded-md"
                key={index}
              >
                <div tw="flex items-center pt-4 pb-4 pl-8 pr-8 bg-white">
                  <Skeleton />
                  <div tw="flex flex-col ml-4 sm:ml-2">
                    <span tw="text-base font-medium">
                      <Skeleton />
                    </span>
                    <span tw="text-xs text-gray-500">
                      <Skeleton />
                    </span>
                  </div>
                </div>
                <span tw="bg-gray-200 w-full text-xs font-light rounded-b-md pl-8 pt-1 pb-1 truncate">
                  <Skeleton />
                </span>
              </div>
            ))}
          </div>
        </>
      )}
      {bestCandidatesStatus === "success" &&
        (bestCandidates.content.length > 0 ? (
          <>
            <h1 tw="text-2xl font-extrabold text-gray-900 mt-20">{t("home.best-profiles")}</h1>
            <div tw="flex flex-wrap justify-center sm:justify-start gap-8 mt-4">
              {bestCandidates.content.map((bestCandidate, index) => (
                <Link
                  to={bestCandidate.jobAdType === 'SCHOOL' ?
                    `/job-ads-school/${bestCandidate.jobAdUuid}/candidates/${bestCandidate.uuid}?status=APPLY&source=${bestCandidate.source}` :
                    `/job-ads/${bestCandidate.jobAdUuid}/candidates/${bestCandidate.uuid}?status=APPLY&source=${bestCandidate.source}`}
                  tw="flex flex-col w-full sm:w-60 truncate shadow-md hover:shadow-lg rounded-md"
                  key={index}
                >
                  <div tw="flex items-center pt-4 pb-4 pl-8 pr-8 bg-white">
                    {/* <JobAdMatchingScore score={bestCandidate.globalMatchingScore} /> */}
                    <JobAdMatchingCustomMatchingGlobalCircleForList score={bestCandidate.globalMatchingScore} />
                    <div tw="flex flex-col ml-4 sm:ml-2">
                      <span tw="text-base font-medium">{bestCandidate.firstname}</span>
                      <span tw="text-xs text-gray-500">
                        {formatDate(new Date(bestCandidate.applicationDate))}
                      </span>
                    </div>
                  </div>
                  <span tw="bg-gray-200 w-full text-xs font-light rounded-b-md pl-8 pt-1 pb-1 truncate">
                    {bestCandidate.jobTitle}
                  </span>
                </Link>
              ))}
            </div>
          </>
        ) : (
          <>
            <h1 tw="text-2xl font-extrabold text-gray-900 mt-20">{t("home.best-profiles")}</h1>
            <div tw="flex flex-wrap justify-center sm:justify-start gap-8 mt-4">
              <span tw="text-base font-medium text-gray-500 whitespace-normal">
                {t("home.nodata-best-profiles")}
              </span>
            </div>
          </>
        ))}
    </>
  );
};
